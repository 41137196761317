import { NgModule } from '@angular/core';
import { VirtualMachinesComponent } from './virtual-machines/virtual-machines.component';
import { MainComponent } from './main.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { UserToAccountPipe } from './user-to-account.pipe';
import { NotificationsComponent } from './notifications/notifications.component';
import { VirtualMachinesTableComponent } from './virtual-machines/virtual-machines-table/virtual-machines-table.component';
import { VirtualMachineTableRowComponent } from './virtual-machines/virtual-machines-table/virtual-machine-table-row/virtual-machine-table-row.component';
import { AdminPasswordFormComponent } from './virtual-machines/virtual-machines-table/virtual-machine-table-row/admin-password-form/admin-password-form.component';
import { RequestVirtualMachineFormComponent } from './virtual-machines/request-virtual-machine-form/request-virtual-machine-form.component';
import { StorageAccountModalComponent } from './virtual-machines/virtual-machines-table/virtual-machine-table-row/storage-account-modal/storage-account-modal.component';
import { StorageAccountKeyComponent } from './virtual-machines/virtual-machines-table/virtual-machine-table-row/storage-account-modal/storage-account-key/storage-account-key.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'virtual-machines',
        component: VirtualMachinesComponent
      },
      {
        path: '**',
        redirectTo: 'virtual-machines'
      }
    ]
  }
];

@NgModule({
  declarations: [
    VirtualMachinesComponent,
    MainComponent,
    UserToAccountPipe,
    NotificationsComponent,
    VirtualMachinesTableComponent,
    VirtualMachineTableRowComponent,
    AdminPasswordFormComponent,
    RequestVirtualMachineFormComponent,
    StorageAccountModalComponent,
    StorageAccountKeyComponent
  ],
  imports: [SharedModule, RouterModule.forChild(routes)]
})
export class MainModule {}

<div class="has-navbar-fixed-top">
  <si-navbar-primary
    appTitle="Cloud Service Portal"
    homeLink="/main"
    [primaryItems]="[]"
    [account]="user | userToAccount"
    [accountItems]="accountItems"
  >
    <si-navbar-item quickAction [item]="notificationsItem"></si-navbar-item>
    <si-navbar-item quickAction [item]="helpItem"></si-navbar-item>
  </si-navbar-primary>
  <si-navbar-vertical
    [items]="menuItems"
    navbarCollapseButtonText="collapse"
    navbarExpandButtonText="expand"
  >
    <div class="si-layout-content-padding">
      <router-outlet></router-outlet>
    </div>
  </si-navbar-vertical>
</div>

<app-notifications
  (close)="showNotifications = false"
  [isVisible]="showNotifications"
></app-notifications>

<div class="modal-header">
  <h4 class="modal-title" id="sample-modal-title">Request a Virtual Machine</h4>
  <button
    type="button"
    class="btn btn-circle btn-sm btn-ghost element-cancel"
    aria-label="Close modal"
    (click)="onClose()"
  ></button>
</div>
<div class="modal-body">
  <p>To order a new virtual machine, please fill out the form below:</p>

  <form [formGroup]="requestVmForm">
    <si-form-item label="Computer Name" [required]="true">
      <div class="form-control-has-icon w-100">
        <input
          type="text"
          id="computerName"
          class="form-control"
          autocomplete="off"
          formControlName="computerName"
        />
        <div *ngIf="computerName.hasError('required')" class="invalid-feedback">
          A value is required.
        </div>
        <div
          *ngIf="computerName.hasError('minlength')"
          class="invalid-feedback"
        >
          The value must be at least 5 characters long.
        </div>
        <div *ngIf="computerName.hasError('pattern')" class="invalid-feedback">
          Value must start with an uppercase letter followed by 2 to 14
          alphanumeric characters (e.g. "VM01").
        </div>
      </div>
    </si-form-item>

    <si-form-item label="Blueprint" [required]="true">
      <select
        class="form-control"
        formControlName="blueprint"
        [id]="'form-class-label'"
      >
        <option [value]="option.id" *ngFor="let option of blueprintOptions">
          {{ option.title }}
        </option>
      </select>

      <div *ngIf="blueprint.hasError('required')" class="invalid-feedback">
        A value is required.
      </div>
    </si-form-item>

    <si-form-item label="Launch Date" [required]="true">
      <div class="form-control-has-icon w-100">
        <input
          type="text"
          class="form-control"
          siDatepicker
          formControlName="launchDate"
          id="launchDate"
        />

        <div *ngIf="launchDate.hasError('required')" class="invalid-feedback">
          A valid date is required.
        </div>
      </div>
    </si-form-item>

    <si-form-item label="Shutdown Date (Only if known)" [required]="false">
      <div class="form-control-has-icon w-100">
        <input
          type="text"
          class="form-control"
          siDatepicker
          formControlName="shutdownDate"
          id="shutdownDate"
        />

        <div *ngIf="shutdownDate.hasError('required')" class="invalid-feedback">
          A value is required.
        </div>
      </div>
    </si-form-item>

    <!-- Sign Up Notifications -->
    <si-inline-notification
      *ngIf="submittedSuccessfully"
      severity="success"
      message="Successfully requested a new virtual machine."
    ></si-inline-notification>
  </form>
</div>
<div class="modal-footer">
  <div class="w-100" *ngIf="isSubmitting">
    <app-progress-bar
      [millisUntilFull]="500"
      label="Submitting form..."
    ></app-progress-bar>
  </div>
  <button type="button" (click)="onClose()" class="btn btn-secondary">
    Cancel
  </button>
  <button
    type="button"
    (click)="onSubmit()"
    [disabled]="!requestVmForm.valid || isSubmitting"
    class="btn btn-primary"
  >
    Submit
  </button>
</div>

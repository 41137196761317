import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, combineLatest, map, switchMap } from 'rxjs';
import { UserService } from '../pages/main/user.service';
import { VirtualMachinesService } from './virtual-machines.service';
import { ENVIRONMENT_TOKEN, Environment } from '../environment.provider';
import { API_OPERATION_CONTEXT } from '../mock-api.interceptor';

interface RequestVirtualMachineBody {
  customerEmail: string;
  resourceGroupName: string;
  computerName: string;
  blueprint: string;
  shutdownDate: string;
  launchDate: string;
}

@Injectable({
  providedIn: 'root'
})
export class RequestVirtualMachineService {
  constructor(
    @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
    private readonly http: HttpClient,
    private readonly userService: UserService,
    private readonly virtualMachinesService: VirtualMachinesService
  ) {}

  requestVirtualMachine(
    computerName: string,
    blueprint: string,
    launchDate: string,
    shutdownDate: string
  ): Observable<void> {
    const userEmail$ = this.userService.user$.pipe(map((u) => u.email));
    const resourceGroupName$ =
      this.virtualMachinesService.resourceGroupNames$.pipe(
        map((rgNames) => rgNames[0])
      );

    return combineLatest([userEmail$, resourceGroupName$]).pipe(
      switchMap(([customerEmail, resourceGroupName]) => {
        const url = `${this.environment.cspBackendUrl}/api/request-virtual-machine`;

        const body: RequestVirtualMachineBody = {
          customerEmail,
          resourceGroupName,
          computerName,
          blueprint,
          launchDate,
          shutdownDate
        };

        return this.http.post<void>(url, body, {
          context: new HttpContext().set(API_OPERATION_CONTEXT, 'request-vm')
        });
      })
    );
  }
}

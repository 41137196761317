import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  ConfirmationDialogResult,
  MenuItem,
  ModalRef,
  SiActionDialogService,
  SiModalService
} from '@simpl/element-ng';
import {
  Department,
  VirtualMachine,
  VirtualMachineStatus
} from '../../../../../services/virtual-machines.service';
import { User } from '../../../user.service';

@Component({
  selector: '[app-virtual-machine-table-row]',
  templateUrl: './virtual-machine-table-row.component.html',
  styleUrls: ['./virtual-machine-table-row.component.scss']
})
export class VirtualMachineTableRowComponent implements OnInit, OnDestroy {
  @Output() start = new EventEmitter();
  @Output() stop = new EventEmitter();
  @Output() restart = new EventEmitter();
  @Output() downloadRdp = new EventEmitter();
  @Output() resetIpAddress = new EventEmitter();
  @Output() changeAdminPassword = new EventEmitter<string>();

  @Input() virtualMachine!: VirtualMachine;
  @Input() filterValue = '';
  @Input() user!: User;

  actions: MenuItem[] = [];

  adminPasswordModal?: ModalRef<unknown>;
  @ViewChild('adminPasswordTmpl') adminPasswordTmpl?: TemplateRef<any>;

  storageAccountModal?: ModalRef<unknown>;
  @ViewChild('storageAccountTmpl') storageAccountTmpl?: TemplateRef<any>;

  ngOnInit(): void {
    this.actions = this.getActions();
  }

  ngOnDestroy(): void {
    this.adminPasswordModal?.detach();
    this.storageAccountModal?.detach();
  }

  constructor(
    private readonly modalService: SiModalService,
    private readonly dialogService: SiActionDialogService
  ) {}

  private getActions(): MenuItem[] {
    return [
      {
        title: 'Start',
        disabled: this.virtualMachine.status !== VirtualMachineStatus.Stopped,
        icon: 'element-play',
        action: () => this.onStart()
      },
      {
        title: 'Stop',
        disabled: this.virtualMachine.status !== VirtualMachineStatus.Running,
        icon: 'element-stop',
        action: () => this.onStop()
      },
      {
        title: 'Restart',
        disabled: this.virtualMachine.status !== VirtualMachineStatus.Running,
        icon: 'element-repeat',
        action: () => this.onRestart()
      },
      { title: '-' },
      {
        title: 'Connect',
        icon: 'element-connected',
        items: [
          {
            title: 'Download RDP File',
            disabled:
              this.virtualMachine.operatingSystem !== 'Windows' ||
              this.virtualMachine.status !== VirtualMachineStatus.Running ||
              this.virtualMachine.crspLink !== null,
            action: () => this.onDownloadRdpFile(),
            icon: 'element-download'
          },
          {
            title: 'Open cRSP Client',
            disabled: this.virtualMachine.crspLink === null,
            action: () => this.onOpenCrspLink(),
            icon: 'element-export'
          }
        ]
      },
      {
        title: 'Open in Azure Portal',
        icon: 'element-export',
        action: () => this.openVmInAzureportal(),
        disabled: this.user.isServiceAgent === false
      },
      { title: '-' },
      {
        title: 'Change Admin Password',
        icon: 'element-edit',
        disabled:
          this.virtualMachine.department === Department.LMS ||
          this.virtualMachine.operatingSystem !== 'Windows' ||
          this.virtualMachine.status !== VirtualMachineStatus.Running,
        action: () => this.onChangeAdminPassword()
      },
      {
        title: 'Reset IP Address',
        icon: 'element-factory-reset',
        action: () => this.onResetIpAddress()
      },
      { title: '-' },
      {
        title: 'Storage Account',
        icon: 'element-database',
        action: () => this.openStorageAccountModal()
      }
    ];
  }

  private onStart(): void {
    this.dialogService
      .showConfirmationDialog(
        `Do you want to start '${this.virtualMachine.computerName}'?`,
        'Start this virtual machine'
      )
      .subscribe((confirmation) => {
        if (confirmation === ConfirmationDialogResult.Confirm) {
          this.start.emit();
        }
      });
  }

  private onStop(): void {
    this.dialogService
      .showConfirmationDialog(
        `Do you want to stop '${this.virtualMachine.computerName}'?`,
        'Stop this virtual machine'
      )
      .subscribe((confirmation) => {
        if (confirmation === ConfirmationDialogResult.Confirm) {
          this.stop.emit();
        }
      });
  }

  private onRestart(): void {
    this.dialogService
      .showConfirmationDialog(
        `Do you want to restart '${this.virtualMachine.computerName}'?`,
        'Restart this virtual machine'
      )
      .subscribe((confirmation) => {
        if (confirmation === ConfirmationDialogResult.Confirm) {
          this.restart.emit();
        }
      });
  }

  private onDownloadRdpFile(): void {
    this.downloadRdp.emit();
  }

  private openVmInAzureportal(): void {
    const url = `https://portal.azure.com/#@SiemensCloud.onmicrosoft.com/resource/subscriptions/17c52ad7-9614-4409-a5eb-ed7ede6a8911/resourceGroups/${this.virtualMachine.resourceGroupName}/providers/Microsoft.Compute/virtualMachines/${this.virtualMachine.name}/overview`;

    window.open(url, '_blank');
  }

  private onResetIpAddress(): void {
    this.dialogService
      .showConfirmationDialog(
        `Do you want to reset the private IP address of '${this.virtualMachine.computerName}' to '${this.virtualMachine.privateIpAddress}'? Please note that in order to apply the update, the virtual machine will need to be restarted.`,
        'Reset IP address'
      )
      .subscribe((confirmation) => {
        if (confirmation === ConfirmationDialogResult.Confirm) {
          this.resetIpAddress.emit();
        }
      });
  }

  private onOpenCrspLink(): void {
    if (this.virtualMachine.crspLink) {
      window.open(this.virtualMachine.crspLink, '_blank');
    }
  }

  private onChangeAdminPassword(): void {
    if (this.adminPasswordTmpl) {
      this.adminPasswordModal = this.modalService.show(this.adminPasswordTmpl, {
        ignoreBackdropClick: false,
        keyboard: true,
        animated: true,
        class: 'modal-dialog-centered'
      });
    }
  }

  private openStorageAccountModal(): void {
    if (this.storageAccountTmpl) {
      this.storageAccountModal = this.modalService.show(
        this.storageAccountTmpl,
        {
          ignoreBackdropClick: false,
          keyboard: true,
          animated: true,
          class: 'modal-dialog-centered'
        }
      );
    }
  }

  onChangeAdminPasswordSubmit(newPassword: string): void {
    this.changeAdminPassword.emit(newPassword);
  }
}

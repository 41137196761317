<div class="mt-5 filter-container">
  <select
    class="form-control"
    (change)="onResourceGroupNameSelect($any($event.target).value)"
  >
    <option
      [value]="option.id"
      *ngFor="let option of resourceGroupSelectOptions"
    >
      {{ option.title }}
    </option>
  </select>

  <input
    class="form-control"
    placeholder="🔍 Filter for any field..."
    (input)="onFilterInput($any($event.target).value)"
  />
</div>

<table class="table elevation-1 mt-5">
  <thead>
    <tr>
      <th>Name</th>
      <th>Resource Group</th>
      <th>Department</th>
      <th>Location</th>
      <th style="width: 130px">Status</th>
      <th>Created Date</th>
      <th>Shutdown Date</th>
      <th>Operating System</th>
      <th style="width: 50px"></th>
    </tr>
  </thead>
  <tbody>
    <tr
      app-virtual-machine-table-row
      [virtualMachine]="vm"
      [filterValue]="filterValue"
      [user]="user"
      (start)="onStartVm(vm)"
      (stop)="onStopVm(vm)"
      (restart)="onRestartVm(vm)"
      (downloadRdp)="onDownloadRdpFile(vm)"
      (resetIpAddress)="onResetIpAddress(vm)"
      (changeAdminPassword)="onChangeAdminPassword(vm, $event)"
      *ngFor="
        let vm of filteredVirtualMachines
          | slice : pageSize * (currentPage - 1)
          | slice : 0 : pageSize
      "
    ></tr>
    <td
      *ngIf="!filteredVirtualMachines.length"
      colspan="100%"
      class="text-center"
    >
      <span *ngIf="filterValue"
        >No virtual machine matches your filter "<strong>{{
          filterValue
        }}</strong
        >"</span
      >
    </td>
  </tbody>
</table>

<div class="d-flex justify-content-between align-items-baseline text-secondary">
  <p>
    <ng-container
      *ngIf="
        (filterValue || selectedResourceGroupName) &&
        filteredVirtualMachines.length
      "
    >
      {{ filteredVirtualMachines.length }}
      virtual
      <span
        >{{ filteredVirtualMachines.length > 1 ? 'machines' : 'machine' }}
      </span>
      <span>{{ filteredVirtualMachines.length > 1 ? 'are' : 'is' }}</span>
      matching the filter
    </ng-container>
  </p>

  <si-pagination
    [totalPages]="totalPages"
    [(currentPage)]="currentPage"
  ></si-pagination>
</div>

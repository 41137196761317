<h1 class="text-secondary page-top-title pt-8">
  {{ 'PAGES.VIRTUAL_MACHINES.HEADING' | translate }}
</h1>

<div
  class="d-flex justify-content-between align-items-baseline"
  *ngIf="!isLoadingVirtualMachines"
>
  <p class="m-0 text-secondary">
    The {{ user.isServiceAgent ? 'service agent' : 'user' }}
    <u>{{ user.email }}</u> is assigned to
    <strong>{{ virtualMachines.length }}</strong> virtual machine{{
      virtualMachines.length > 1 ? 's' : ''
    }}
    in <strong>{{ totalResourceGroups }}</strong> resource group{{
      totalResourceGroups > 1 ? 's' : ''
    }}.
  </p>

  <button
    (click)="onRequestVirtualMachine(requestVirtualMachineTmpl)"
    type="button"
    class="btn btn-secondary"
  >
    <i class="icon element-plus"></i>Order a Virtual Machine
  </button>
</div>

<app-virtual-machines-table
  *ngIf="!isLoadingVirtualMachines"
  [virtualMachines]="virtualMachines"
  [user]="user"
  (start)="onStartVm($event)"
  (stop)="onStopVm($event)"
  (restart)="onRestartVm($event)"
  (downloadRdp)="onDownloadRdpFile($event)"
  (resetIpAddress)="onResetIpAddress($event)"
  (changeAdminPassword)="onChangeAdminPassword($event)"
></app-virtual-machines-table>

<div
  *ngIf="isLoadingVirtualMachines"
  class="loading-container text-center text-secondary"
>
  <si-loading-spinner></si-loading-spinner>
  Loading virtual machines...
</div>

<ng-template #requestVirtualMachineTmpl let-modalRef="modalRef">
  <app-request-virtual-machine-form
    (close)="modalRef.hide()"
  ></app-request-virtual-machine-form>
</ng-template>

import { Component, Input, OnInit } from '@angular/core';
import { interval, take } from 'rxjs';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() millisUntilFull = 2000;
  @Input() label = '';
  value = 0;

  ngOnInit(): void {
    const step = this.millisUntilFull / 10;
    interval(step)
      .pipe(take(10))
      .subscribe((i) => {
        this.value = (i + 1) * 10;
      });
  }
}

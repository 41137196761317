import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-dots',
  template: `<span>{{ loadingDots }}</span>`
})
export class LoadingDotsComponent {
  @Input() tickInterval = 1000; // duration between each dot in milliseconds
  loadingDots = '';
  private interval: any;

  ngOnInit() {
    this.interval = setInterval(() => {
      if (this.loadingDots.length < 3) {
        this.loadingDots += '.';
      } else {
        this.loadingDots = '';
      }
    }, this.tickInterval);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}

import { NgModule } from '@angular/core';
import {
  SiLandingPageModule,
  SimplElementNgModule,
  SiContentActionBarModule,
  SiActionDialogModule,
  SiLoadingSpinnerModule,
  SiFilteredSearchModule,
  SiTypeaheadModule,
  SiInlineNotificationModule,
  SiProgressbarModule,
  SiSidePanelModule,
  SiEmptyStateModule,
  SiDatepickerModule
} from '@simpl/element-ng';

@NgModule({
  exports: [
    SiLandingPageModule,
    SimplElementNgModule,
    SiTypeaheadModule,
    SiFilteredSearchModule,
    SiEmptyStateModule,
    SiDatepickerModule,
    SiContentActionBarModule,
    SiInlineNotificationModule,
    SiProgressbarModule,
    SiSidePanelModule,
    SiActionDialogModule,
    SiLoadingSpinnerModule
  ]
})
export class SharedSimplElementModule {}

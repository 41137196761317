import { Component, Input } from '@angular/core';
import { StorageAccountKey } from '../../../../../../../services/storage-accounts.service';

@Component({
  selector: 'app-storage-account-key',
  templateUrl: './storage-account-key.component.html',
  styleUrls: ['./storage-account-key.component.scss']
})
export class StorageAccountKeyComponent {
  @Input() key!: StorageAccountKey;
  isKeyVisible = false;

  onCopy(): void {
    // Copies the key-value to the user's clipboard.
    navigator.clipboard.writeText(this.key.value);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { AccountItem } from '@simpl/element-ng';
import { User } from './user.service';

@Pipe({
  name: 'userToAccount'
})
export class UserToAccountPipe implements PipeTransform {
  /**
   * Transforms an Auth0 User into an AccountItem for display in the app header.
   */
  transform(user: User | undefined | null): AccountItem {
    return {
      title: `${user?.firstName} ${user?.lastName}`,
      company: user?.company,
      email: user?.email,
      role: user?.isServiceAgent ? 'Service Agent 😎' : 'User'
    };
  }
}

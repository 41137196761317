import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Environment, ENVIRONMENT_TOKEN } from '../environment.provider';
import { API_OPERATION_CONTEXT } from '../mock-api.interceptor';

export interface SiemensIdSignUpUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface SignUpError {
  name: 'BadRequestError';
  code: 'user_exists';
  description: string;
  statusCode: 400;
}

@Injectable({
  providedIn: 'root'
})
export class SiemensIdSignUpService {
  constructor(
    @Inject(ENVIRONMENT_TOKEN) private readonly env: Environment,
    private readonly http: HttpClient
  ) {}

  /**
   * Signs up a user at the current auth0 tenant (staging or production).
   * The observable will emit an error if the user already exists.
   * You can identify that error by its `code` property which will be "user_exists".
   */
  signUp(user: SiemensIdSignUpUser): Observable<void> {
    const url = this.env.auth.siemensIdSignUp.url;

    return this.http.post<void>(
      url,
      {
        client_id: this.env.auth.siemensIdSignUp.clientId,
        connection: 'Username-Password-Authentication',
        email: user.email,
        password: user.password,
        user_metadata: {
          given_name: user.firstName,
          family_name: user.lastName
        }
      },
      { context: new HttpContext().set(API_OPERATION_CONTEXT, 'signup') }
    );
  }
}

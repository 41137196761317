import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT_TOKEN, Environment } from '../environment.provider';
import { API_OPERATION_CONTEXT } from '../mock-api.interceptor';

@Injectable({
  providedIn: 'root'
})
export class ValidateAccessTokenService {
  constructor(
    @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
    private readonly http: HttpClient
  ) {}

  validateAccessToken(): Observable<void> {
    const url = `${this.environment.cspBackendUrl}/api/validate-access-token`;

    return this.http.get<void>(url, {
      context: new HttpContext().set(
        API_OPERATION_CONTEXT,
        'validate-access-token'
      )
    });
  }
}

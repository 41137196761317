import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> | boolean {
    const shouldSkip =
      this.router.getCurrentNavigation()?.extras.state?.['skipAuthGuard'];

    if (shouldSkip) {
      /*
       * The `skipAuthGuard` flag is passed as state to the navigation by the `LandingPageComponent`
       * after the user has successfully authenticated. This is necessary because the `isAuthenticated$` source
       * may not have updated yet and would emit `false`, causing this guard to wrongly block access
       * to the main part of the app. With the `skipAuthGuard` flag, the guard can properly allow
       * the user to access the app without being redirected.
       */
      return true;
    }

    return this.auth.isAuthenticated$.pipe(
      map((isAuthenticated) => {
        if (isAuthenticated) {
          return true;
        } else {
          // Include a query parameter in the redirect URL to provide
          // information for the landing page to display an appropriate alert.
          this.router.navigate([`${window.location.origin}/#/landing`], {
            queryParams: { blockedByGuard: true }
          });
          return false;
        }
      })
    );
  }
}

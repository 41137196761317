import { InjectionToken, Provider } from '@angular/core';
import { environment } from 'src/environments/environment';

// Token for components and services to inject.
export const ENVIRONMENT_TOKEN = new InjectionToken<Environment>(
  'Environment Injection Token'
);

// Type for the injected environment object.
export type Environment = typeof environment;

// Provider for AppModule to provide.
export const environmentProvider: Provider = {
  provide: ENVIRONMENT_TOKEN,
  useValue: environment
};

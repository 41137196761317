import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FriendlyDatePipe } from '../../../../shared/friendly-date.pipe';
import { VirtualMachine } from '../../../../services/virtual-machines.service';
import { SelectOption } from '@simpl/element-ng';
import distinct from '../../../../utils/distinct';
import { User } from '../../user.service';

@Component({
  selector: 'app-virtual-machines-table',
  templateUrl: './virtual-machines-table.component.html',
  styleUrls: ['./virtual-machines-table.component.scss']
})
export class VirtualMachinesTableComponent implements OnChanges {
  @Input() virtualMachines: VirtualMachine[] = [];
  @Input() user!: User;

  @Output() start = new EventEmitter<VirtualMachine>();
  @Output() stop = new EventEmitter<VirtualMachine>();
  @Output() restart = new EventEmitter<VirtualMachine>();
  @Output() downloadRdp = new EventEmitter<VirtualMachine>();
  @Output() resetIpAddress = new EventEmitter<VirtualMachine>();
  @Output() changeAdminPassword = new EventEmitter<{
    virtualMachine: VirtualMachine;
    newPassword: string;
  }>();

  filteredVirtualMachines: VirtualMachine[] = [];
  filterValue = '';
  resourceGroupSelectOptions: SelectOption[] = [];
  selectedResourceGroupName = '';

  // Paging.
  currentPage = 1;
  pageSize = 9;
  totalPages = 0;

  resourceGroupsCount = 0;

  constructor(private readonly prettyDatePipe: FriendlyDatePipe) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['virtualMachines']) {
      // Will be called when the reference of the virtualMachines input changes.

      this.resourceGroupSelectOptions = this.getResourceGroupSelectOptions();
      this.filterVms();
      this.updatePaging({ setTotalPages: true });
      this.resourceGroupsCount = distinct(
        this.virtualMachines.map((vm) => vm.resourceGroupName)
      ).length;
    }
  }

  private getResourceGroupSelectOptions(): SelectOption[] {
    // First, extract distinct resource group names from the list of virtual machines
    const distinctResourceGroupNames = distinct<string>(
      this.virtualMachines.map((vm) => vm.resourceGroupName)
    );

    // Next, convert each distinct resource group name to a SelectOption object
    const resourceGroupSelectOptions =
      distinctResourceGroupNames.map<SelectOption>((rg) => ({
        id: rg,
        title: `${rg} (${
          (
            this.virtualMachines.find(
              (vm) => vm.resourceGroupName === rg
            ) as VirtualMachine
          ).department
        })`
      }));

    // If there is more than one resource group, create and add the default option for displaying all resource groups
    if (resourceGroupSelectOptions.length > 1) {
      const defaultOption = {
        id: '',
        title: 'All Resource Groups'
      };
      resourceGroupSelectOptions.unshift(defaultOption);
    }

    return resourceGroupSelectOptions;
  }

  onResourceGroupNameSelect(resourceGroupName: any): void {
    this.selectedResourceGroupName = resourceGroupName;
    this.filterVms();
    this.updatePaging({ setTotalPages: true, resetCurrentPage: true });
  }

  onFilterInput(filterValue: string): void {
    this.filterValue = filterValue;
    this.filterVms();
    this.updatePaging({ setTotalPages: true, resetCurrentPage: true });
  }

  /**
   * Filters the list of virtual machines based on the user's
   * filter value, searching through all VM properties.
   */
  private filterVms(): void {
    const normalizedFilterValue = this.filterValue.toUpperCase();

    const filterByDate = (key: string, value: string): boolean => {
      if (key === 'createdDate' || key === 'shutdownDate') {
        const formattedDate = this.prettyDatePipe.transform(value) as string;
        return formattedDate.includes(this.filterValue);
      }
      return false;
    };

    const filterByString = (value: string): boolean => {
      return value.toUpperCase().includes(normalizedFilterValue);
    };

    this.filteredVirtualMachines = this.virtualMachines.filter((vm) =>
      vm.resourceGroupName.includes(this.selectedResourceGroupName)
    );

    // Properties to be excluded from the filtering process.
    const keysToIgnore = [
      'name',
      'size',
      'privateIpAddress',
      'diskSizeGB',
      'crspLink'
    ];

    this.filteredVirtualMachines = this.filteredVirtualMachines.filter((vm) => {
      return Object.entries(vm).some(([key, value]) => {
        if (keysToIgnore.includes(key)) {
          return false;
        }

        if (typeof value === 'string') {
          return filterByDate(key, value) || filterByString(value);
        }

        return false;
      });
    });
  }

  onStartVm(virtualMachine: VirtualMachine): void {
    this.start.emit(virtualMachine);
  }

  onStopVm(virtualMachine: VirtualMachine): void {
    this.stop.emit(virtualMachine);
  }

  onRestartVm(virtualMachine: VirtualMachine): void {
    this.restart.emit(virtualMachine);
  }

  onDownloadRdpFile(virtualMachine: VirtualMachine): void {
    this.downloadRdp.emit(virtualMachine);
  }

  onResetIpAddress(virtualMachine: VirtualMachine): void {
    this.resetIpAddress.emit(virtualMachine);
  }

  onChangeAdminPassword(
    virtualMachine: VirtualMachine,
    newPassword: string
  ): void {
    this.changeAdminPassword.emit({ virtualMachine, newPassword });
  }

  private updatePaging({
    setTotalPages = false,
    resetCurrentPage = false
  }: {
    setTotalPages?: boolean;
    resetCurrentPage?: boolean;
  } = {}): void {
    if (setTotalPages) {
      this.totalPages = Math.ceil(
        this.filteredVirtualMachines.length / this.pageSize
      );
    }

    if (resetCurrentPage) {
      this.currentPage = 1;
    }
  }
}

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_OPERATION_CONTEXT } from '../mock-api.interceptor';

export interface Operation {
  id: string;
  status: OperationStatus;
  createdDate: string;
}

export enum OperationStatus {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS'
}

@Injectable({
  providedIn: 'root'
})
export class OperationService {
  constructor(private readonly http: HttpClient) {}

  getOperation(operationUrl: string): Observable<Operation> {
    const context = new HttpContext().set(
      API_OPERATION_CONTEXT,
      'get-operation'
    );

    return this.http.get<Operation>(operationUrl, {
      context
    });
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { MenuItem, selectMenuItemById, ThemeType } from '@simpl/element-ng';
import { filter } from 'rxjs';
import { NotificationsService } from '../../services/notifications.service';
import { SettingsService } from '../../services/settings.service';
import { VirtualMachinesService } from '../../services/virtual-machines.service';
import { UserService } from './user.service';
import { SubSink } from 'subsink';

@Component({
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  private subsink = new SubSink();

  private languageItems: MenuItem[] =
    this.settingsService.availableLanguages.map((language) => ({
      id: language,
      title: `LANGUAGE.${language.toUpperCase()}`,
      action: () => this.switchLanguage(language)
    }));

  private themeItems: MenuItem[] = [
    { id: 'auto', title: 'THEME.AUTO', action: () => this.switchTheme('auto') },
    {
      id: 'light',
      title: 'THEME.LIGHT',
      action: () => this.switchTheme('light')
    },
    { id: 'dark', title: 'THEME.DARK', action: () => this.switchTheme('dark') }
  ];

  menuItems: MenuItem[] = [
    {
      title: 'NAV.VIRTUAL_MACHINES',
      icon: 'element-plant',
      link: 'virtual-machines'
    }
  ];

  helpItem: MenuItem = {
    title: 'Help',
    icon: 'element-help',
    items: [
      { title: 'NAV.ABOUT', link: '/main/about' },
      { title: '-' },
      {
        title: 'Corporate Information',
        href: 'https://www.siemens.com/global/en/general/legal.html',
        target: '_blank'
      }
    ]
  };

  // Notifications Sidenav
  showNotifications = false;
  unseenNotifications = 0;
  notificationsItem: MenuItem = {
    title: 'Notifications',
    icon: 'element-alarm',
    action: () => this.toggleNotifications(),
    badge: this.unseenNotifications
  };

  accountItems: MenuItem[] = [
    {
      title: 'NAV.LANGUAGE',
      icon: 'element-language',
      items: this.languageItems
    },
    { title: 'NAV.THEME', icon: 'element-palette', items: this.themeItems },
    { title: '-' },
    {
      title: 'NAV.LOGOUT',
      icon: 'element-logout',
      action: () =>
        this.authService.logout({
          logoutParams: {
            returnTo: `${window.location.origin}/#/landing`
          }
        })
    }
  ];

  user = this.userService.createDefaultUser();

  constructor(
    private readonly settingsService: SettingsService,
    private readonly authService: AuthService,
    private readonly notificationsService: NotificationsService,
    private readonly virtualMachinesService: VirtualMachinesService,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    // Initially load app data.
    this.virtualMachinesService.loadVirtualMachines();

    this.languageItems = selectMenuItemById(
      this.languageItems,
      this.settingsService.language
    );

    this.themeItems = selectMenuItemById(
      this.themeItems,
      this.settingsService.theme
    );

    this.subsink.sink = this.notificationsService.notifications$
      .pipe(filter((n) => !n.notificationToReplace)) // Only update the badge for new notifications.
      .subscribe(() => {
        if (this.showNotifications) {
          // The notifications sidenav is open so the user is already seeing the new notification.
        } else {
          // The sidenav is closed so we inform the user about the notification by incrementing the badge number.
          this.unseenNotifications++;
          this.updateUnseenNotificationsBadge();
        }
      });

    this.subsink.sink = this.userService.user$.subscribe(
      (u) => (this.user = u)
    );
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  switchLanguage(language: string) {
    this.settingsService.setLanguage(language);
    this.languageItems = selectMenuItemById(this.languageItems, language);
  }

  switchTheme(theme: ThemeType) {
    this.settingsService.setTheme(theme);
    this.themeItems = selectMenuItemById(this.themeItems, theme);
  }

  private toggleNotifications(): void {
    if (!this.showNotifications) {
      // The user just opened the notifications sidenav, thus seeing every notification.
      // We can reset the unseenNotifications count to 0.
      this.unseenNotifications = 0;
      this.updateUnseenNotificationsBadge();
    }

    this.showNotifications = !this.showNotifications;
  }

  private updateUnseenNotificationsBadge(): void {
    this.notificationsItem.badge = this.unseenNotifications;
  }
}

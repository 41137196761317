import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LandingComponent } from './pages/landing/landing.component';
import { MainModule } from './pages/main/main.module';

const routes: Routes = [
  { path: 'landing', component: LandingComponent },
  {
    path: 'main',
    loadChildren: () => MainModule,
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: 'landing' }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

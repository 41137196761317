import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AlertConfig, ModalRef, SiModalService } from '@simpl/element-ng';
import { switchMap, take } from 'rxjs';
import { SubSink } from 'subsink';
import { SettingsService } from '../../services/settings.service';
import { ValidateAccessTokenService } from '../../services/validate-access-token.service';

@Component({
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  private ref?: ModalRef<unknown>;

  availableLanguages = this.settingsService.availableLanguages;
  loginAlert?: AlertConfig;
  loginStarted = false;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly modalService: SiModalService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly settingsService: SettingsService,
    private readonly validateAccessTokenService: ValidateAccessTokenService
  ) {}

  ngOnInit(): void {
    // Navigate to the main part of the app if the user is already authenticated.
    this.subs.sink = this.authService.isAuthenticated$
      .pipe(take(1))
      .subscribe((isAuthenticated) =>
        isAuthenticated ? this.router.navigateByUrl('/main') : {}
      );

    this.subs.sink = this.activatedRoute.queryParamMap.subscribe(
      (queryParams) => {
        if (queryParams.get('expiredSession') === 'true') {
          // The refresh token expired, logging the user out and redirecting them
          // to this component with an `expiredSession` query parameter in the URL.
          this.loginAlert = this.createLoginAlert(
            'Your session has expired. Please sign in again.'
          );
        }

        if (queryParams.get('blockedByGuard') === 'true') {
          // The user user was denied access to a route by our AuthGuard.
          this.loginAlert = this.createLoginAlert(
            "To access the main part of the application, please sign in. You were redirected because you don't have authorization. Thank you."
          );
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ref?.detach();
  }

  /**
   * Starts the Auth0 login flow using a popup window with the Auth0 login page (e.g. Siemens ID).
   * - Redirects to the main application page if login is successful.
   * - Sets an error alert if the user cancels the login flow.
   */
  async onLogin() {
    this.loginStarted = true;

    this.subs.sink = this.authService
      .loginWithPopup(
        {},
        {
          popup: this.createLoginPopupWindow()
        }
      )
      .pipe(
        switchMap(() => this.validateAccessTokenService.validateAccessToken())
      )
      .subscribe({
        next: () => {
          this.loginAlert = undefined;
          this.router.navigateByUrl('/main', {
            state: { skipAuthGuard: true }
          });
        },
        error: (err) => {
          if (err instanceof HttpErrorResponse) {
            // Invalid access token.
            this.loginAlert = this.createLoginAlert(
              'Please sign in with an account associated with the Cloud Service Portal, or contact support to request service agent privileges.'
            );
          } else {
            // User cancelled the OAuth flow.
            this.loginAlert = this.createLoginAlert(
              'Authentication is required to access this app. Please follow the authentication flow to sign in.'
            );
          }

          this.loginStarted = false;
          this.clearAuth0LocalStorageTokens();
        }
      });
  }

  /**
   * Clears Auth0 tokens from the local storage.
   *
   * If we want to avoid a redirect by the official auth0 logout method,
   *  we have to clear the Auth0 tokens from the local storage ourselves.
   */
  private clearAuth0LocalStorageTokens(): void {
    for (let i = localStorage.length - 1; i >= 0; i--) {
      const key = localStorage.key(i);
      if (key && key.startsWith('@@auth0')) {
        localStorage.removeItem(key);
      }
    }
  }

  private createLoginAlert(message: string): AlertConfig {
    return {
      heading: 'Authentication Required',
      severity: 'danger',
      message
    };
  }

  /**
   * Creates a new popup window for the Auth0 login flow with the
   * specified dimensions and position centered on the user's screen.
   */
  private createLoginPopupWindow(): Window | null {
    const w = 566;
    const h = 700;
    const left = window.screen.width / 2 - w / 2;
    const top = window.screen.height / 2 - h / 2;

    const popup = window.open(
      '',
      'auth0:authorize:popup',
      `left=${left},top=${top},width=${w},height=${h}`
    );

    return popup;
  }

  onSignUp(signUpModalTmpl: TemplateRef<any>): void {
    this.ref = this.modalService.show(signUpModalTmpl, {
      ignoreBackdropClick: false,
      keyboard: true,
      animated: true,
      class: 'modal-dialog-centered'
    });
  }
}

<si-side-panel
  *ngIf="isVisible"
  [collapsed]="false"
  (closed)="onClose()"
  mode="over"
>
  <si-side-panel-content heading="Notifications">
    <div
      *ngIf="notifications.length; else emptyNotificationsTmpl"
      class="list-group"
    >
      <ng-container
        *ngFor="let notification of notifications; let isLast = last"
      >
        <hr />

        <div class="list-group-item">
          <div
            class="d-flex w-100 justify-content-between align-items-center mb-5"
          >
            <h4 class="m-0 d-flex align-items-center">
              <ng-container [ngSwitch]="notification.status">
                <si-icon
                  *ngSwitchCase="'success'"
                  icon="element-circle-filled"
                  color="status-success"
                  stackedIcon="element-state-tick"
                  stackedColor="status-success-contrast"
                  size="display-6"
                ></si-icon>

                <si-icon
                  *ngSwitchCase="'failed'"
                  icon="element-circle-filled"
                  color="status-danger"
                  stackedIcon="element-state-exclamation-mark"
                  stackedColor="status-danger-contrast"
                  size="display-6"
                ></si-icon>

                <si-loading-spinner *ngSwitchCase="'running'">
                </si-loading-spinner>
              </ng-container>

              <span class="pl-5">{{ notification.title }}</span>
            </h4>
            <button
              type="button"
              class="btn btn-circle btn-sm btn-ghost element-delete"
              aria-label="delete"
              (click)="onDelete(notification)"
            ></button>
          </div>
          <p class="mb-2">
            {{ notification.message }}
          </p>
          <small>{{ notification?.createdDate | diffFromCurrent }}</small>
        </div>
      </ng-container>
    </div>

    <ng-template #emptyNotificationsTmpl>
      <si-empty-state
        icon="element-alarm-acknowledged"
        heading="No Notifications"
        content="There are no notifications."
      >
      </si-empty-state>
    </ng-template>
  </si-side-panel-content>
</si-side-panel>


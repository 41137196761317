import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  NotificationsService,
  Notification
} from '../../../services/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @Input() isVisible = false;
  @Output() close = new EventEmitter<void>();

  notifications: Notification[] = [];

  constructor(private readonly notificationsService: NotificationsService) {}

  ngOnInit(): void {
    this.notificationsService.notifications$.subscribe((notification) => {
      if (notification.notificationToReplace) {
        // This notification replaces another notification.
        // For example: "VM started" will replace "VM is starting".
        this.notifications = this.notifications.map((n) => {
          if (n.id === notification.notificationToReplace) {
            return notification;
          } else {
            return n;
          }
        });
      } else {
        this.notifications = [notification, ...this.notifications];
      }
    });
  }

  onClose(): void {
    this.close.emit();
  }

  onDelete(notification: Notification): void {
    this.notifications = this.notifications.filter((n) => n !== notification);
  }
}

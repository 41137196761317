import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { filter } from 'rxjs';
import {
  VirtualMachine,
  VirtualMachinesService
} from '../../../services/virtual-machines.service';
import { SubSink } from 'subsink';
import getRdpFileContent from './virtual-machines-table/get-rdp-file-content';
import distinct from '../../../utils/distinct';
import { UserService } from '../user.service';
import { MenuItem, ModalRef, SiModalService } from '@simpl/element-ng';

@Component({
  templateUrl: './virtual-machines.component.html',
  styleUrls: ['./virtual-machines.component.scss']
})
export class VirtualMachinesComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  private ref?: ModalRef<unknown>;

  virtualMachines: VirtualMachine[] = [];
  isLoadingVirtualMachines = false;
  user = this.userService.createDefaultUser();

  totalResourceGroups = 0;

  primaryActionsIcons: MenuItem[] = [
    { title: 'Share', action: () => alert('Share'), icon: 'element-share' }
  ];

  constructor(
    private readonly userService: UserService,
    private readonly virtualMachineService: VirtualMachinesService,
    private readonly modalService: SiModalService
  ) {}

  ngOnInit(): void {
    this.isLoadingVirtualMachines = true;
    this.subs.sink = this.virtualMachineService.virtualMachines$
      .pipe(filter((vms) => vms.length !== 0))
      .subscribe((vms) => {
        this.virtualMachines = vms;
        this.totalResourceGroups = distinct(
          vms.map((vm) => vm.resourceGroupName)
        ).length;
        this.isLoadingVirtualMachines = false;
      });

    this.subs.sink = this.userService.user$.subscribe((u) => (this.user = u));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ref?.detach();
  }

  onStartVm(vm: VirtualMachine): void {
    this.virtualMachineService.startVirtualMachine(vm);
  }

  onStopVm(vm: VirtualMachine): void {
    this.virtualMachineService.stopVirtualMachine(vm);
  }

  onRestartVm(vm: VirtualMachine): void {
    this.virtualMachineService.restartVirtualMachine(vm);
  }

  onResetIpAddress(vm: VirtualMachine): void {
    this.virtualMachineService.resetIpAddress(vm);
  }

  onDownloadRdpFile(vm: VirtualMachine): void {
    const content = getRdpFileContent(vm, this.user);

    const blob = new Blob([content], { type: 'application/octet-stream' });

    const url = window.URL.createObjectURL(blob);

    // Create a temporary <a> element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = `${vm.computerName}.${vm.privateIpAddress.replace(
      /\./g,
      '-'
    )}.rdp`;
    a.click();

    window.URL.revokeObjectURL(url);
  }

  onChangeAdminPassword(event: {
    virtualMachine: VirtualMachine;
    newPassword: string;
  }): void {
    const { virtualMachine, newPassword } = event;
    this.virtualMachineService.changeAdminPassword(virtualMachine, newPassword);
  }

  onRequestVirtualMachine(signUpModalTmpl: TemplateRef<any>): void {
    this.ref = this.modalService.show(signUpModalTmpl, {
      ignoreBackdropClick: false,
      keyboard: true,
      animated: true,
      class: 'modal-dialog-centered'
    });
  }
}

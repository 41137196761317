import { Component, EventEmitter, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  SiemensIdSignUpService,
  SiemensIdSignUpUser,
  SignUpError
} from '../../../services/siemens-id-sign-up.service';
import passwordValidator from '../../../utils/password-validator';

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss']
})
export class SignUpFormComponent {
  @Output() close = new EventEmitter();
  signUpForm = this.initFormGroup();
  showPasswordHints = false;

  signUpErrorCode = '';
  signedUpSuccessfully = false;
  isSigningIn = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly signUpService: SiemensIdSignUpService
  ) {}

  get firstName(): AbstractControl {
    return this.signUpForm.get('firstName') as AbstractControl;
  }

  get lastName(): AbstractControl {
    return this.signUpForm.get('lastName') as AbstractControl;
  }

  get email(): AbstractControl {
    return this.signUpForm.get('email') as AbstractControl;
  }

  get password(): AbstractControl {
    return this.signUpForm.get('password') as AbstractControl;
  }

  get acceptTerms(): AbstractControl {
    return this.signUpForm.get('acceptTerms') as AbstractControl;
  }

  private initFormGroup() {
    return this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      email: [
        '',
        [Validators.required, Validators.email, this.noSiemensEmailValidator()]
      ],
      password: ['', [passwordValidator()]],
      acceptTerms: [false, [Validators.requiredTrue]]
    });
  }

  private noSiemensEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = control.value
        ? control.value.endsWith('@siemens.com')
        : false;
      return forbidden ? { forbiddenEmail: { value: control.value } } : null;
    };
  }

  onSubmit(): void {
    this.isSigningIn = true;
    this.signUpErrorCode = '';
    this.signedUpSuccessfully = false;

    const user: SiemensIdSignUpUser = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      email: this.email.value,
      password: this.password.value
    };

    this.signUpService.signUp(user).subscribe({
      next: () => {
        this.signUpErrorCode = '';
        this.signedUpSuccessfully = true;
        this.isSigningIn = false;

        // Reset the form.
        this.signUpForm = this.initFormGroup();
      },
      error: (errorResponse: { error: SignUpError }) => {
        this.signUpErrorCode = errorResponse.error.code;
        this.isSigningIn = false;
      }
    });
  }

  onClose(): void {
    this.close.emit();
  }
}

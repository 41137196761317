import { Component, EventEmitter, Output } from '@angular/core';
import {
  FormBuilder,
  AbstractControl,
  ValidatorFn,
  FormControl,
  ValidationErrors
} from '@angular/forms';

@Component({
  selector: 'app-admin-password-form',
  templateUrl: './admin-password-form.component.html',
  styleUrls: ['./admin-password-form.component.scss']
})
export class AdminPasswordFormComponent {
  @Output() close = new EventEmitter();
  @Output() submit = new EventEmitter<string>();
  signUpForm = this.initFormGroup();
  showPasswordHints = false;

  isPasswordVisible = false;
  isPasswordConfirmVisible = false;

  constructor(private readonly fb: FormBuilder) {}

  get password(): AbstractControl {
    return this.signUpForm.get('password') as AbstractControl;
  }

  get passwordConfirm(): AbstractControl {
    return this.signUpForm.get('passwordConfirm') as AbstractControl;
  }

  private initFormGroup() {
    return this.fb.group({
      password: ['', [this.passwordValidator()]],
      passwordConfirm: ['']
    });
  }

  private passwordValidator(): ValidatorFn | any {
    return (control: FormControl): ValidationErrors | null => {
      const value = control.value as string;

      const hasMinLength = value.length >= 12;
      const hasLowerCase = /[a-z]/.test(value);
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasSpecialChar = /[!@#_$%\^&*\?]/.test(value);

      if (
        hasLowerCase &&
        hasUpperCase &&
        hasNumber &&
        hasSpecialChar &&
        hasMinLength
      ) {
        // Valid password.
        return null;
      } else {
        return {
          password: {
            hasMinLength,
            hasLowerCase,
            hasUpperCase,
            hasNumber,
            hasSpecialChar
          }
        };
      }
    };
  }

  onSubmit(): void {
    this.submit.emit(this.password.value);
  }

  onClose(): void {
    this.close.emit();
  }
}

import { Component, EventEmitter, Output } from '@angular/core';
import {
  FormBuilder,
  AbstractControl,
  Validators,
  ValidatorFn
} from '@angular/forms';
import { SelectOption } from '@simpl/element-ng';
import { RequestVirtualMachineService } from '../../../../services/request-virtual-machine.service';

@Component({
  selector: 'app-request-virtual-machine-form',
  templateUrl: './request-virtual-machine-form.component.html',
  styleUrls: ['./request-virtual-machine-form.component.scss']
})
export class RequestVirtualMachineFormComponent {
  @Output() close = new EventEmitter();
  blueprintOptions = this.getVirtualMachineBlueprintsAsSelectOptions();
  requestVmForm = this.initFormGroup();

  submittedSuccessfully = false;
  isSubmitting = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly requestVmService: RequestVirtualMachineService
  ) {}

  get computerName(): AbstractControl {
    return this.requestVmForm.get('computerName') as AbstractControl;
  }

  get blueprint(): AbstractControl {
    return this.requestVmForm.get('blueprint') as AbstractControl;
  }

  get launchDate(): AbstractControl {
    return this.requestVmForm.get('launchDate') as AbstractControl;
  }

  get shutdownDate(): AbstractControl {
    return this.requestVmForm.get('shutdownDate') as AbstractControl;
  }

  private initFormGroup() {
    return this.fb.group({
      computerName: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[A-Z]{1}[A-Za-z0-9]{2,14}$/),
          this.noSiemensEmailValidator()
        ]
      ],
      blueprint: [this.blueprintOptions[0].title, [Validators.required]],
      launchDate: [new Date(), [Validators.required]],
      shutdownDate: ['']
    });
  }

  private getVirtualMachineBlueprintsAsSelectOptions(): SelectOption[] {
    const blueprints = [
      'SIVaaS V3.6 Az PCS 7 V9.0 Sp3 Engineeringstation',
      'SIVaaS V3.6 Az PCS 7 V9.0 Sp3 OSSingleStation',
      'SIVaaS V3.6 Az PCS 7 V9.1 Engineeringstation',
      'SIVaaS V3.6 Az PCS 7 V9.1 OSServer',
      'SIVaaS V3.6 Az PCS 7 V9.1 OSSingleStation',
      'SIVaaS V3.6 Az Windows 2016',
      'SIVaaS V3.6 Az Windows 2019',
      'SIVaaS V3.6.1 Az PCS 7 V9.1 SP1 Engineeringstation',
      'SIVaaS V3.7 Az PCS 7 V9.1 SP1 Engineeringstation',
      'SIVaaS V3.7 Az PCS 7 V9.1 SP2 Engineeringstation',
      'SIVaaS V3.7 Az SIMIT V11.0.1 SF',
      'SIVaaS V3.7 Az Windows 2019',
      'SIVaaS V3.7 Az Windows 2022'
    ];

    const blueprintOptions = blueprints.map((bp) => ({ id: bp, title: bp }));

    return blueprintOptions;
  }

  private noSiemensEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = control.value
        ? control.value.endsWith('@siemens.com')
        : false;
      return forbidden ? { forbiddenEmail: { value: control.value } } : null;
    };
  }

  onSubmit(): void {
    this.isSubmitting = true;
    this.submittedSuccessfully = false;

    this.requestVmService
      .requestVirtualMachine(
        this.computerName.value,
        this.blueprint.value,
        this.launchDate.value,
        this.shutdownDate.value
      )
      .subscribe(() => {
        this.isSubmitting = false;
        this.submittedSuccessfully = true;
      });
  }

  onClose(): void {
    this.close.emit();
  }
}

import { Injectable } from '@angular/core';
import { AuthService, User as Auth0User } from '@auth0/auth0-angular';
import isDefined from '../../utils/is-defined';
import { BehaviorSubject, filter, map } from 'rxjs';

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  isServiceAgent: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userSubject = new BehaviorSubject(this.createDefaultUser());
  user$ = this.userSubject.asObservable();

  constructor(authService: AuthService) {
    authService.user$
      .pipe(
        filter(isDefined),
        map((user) => this.fromAuth0User(user))
      )
      .subscribe((user) => this.userSubject.next(user));
  }

  private fromAuth0User(auth0User: Auth0User): User {
    const company = this.getCompanyFromEmail(auth0User?.email as string);

    return {
      firstName: auth0User.given_name as string,
      lastName: auth0User.family_name as string,
      email: auth0User.email as string,
      isServiceAgent:
        auth0User['https://siemens.com/roles'].includes('CSP Service Agent'),
      company
    };
  }

  private getCompanyFromEmail(email: string): string {
    const domain = email
      .substring(email.lastIndexOf('@') + 1, email.lastIndexOf('.com'))
      .toLowerCase();

    const company = domain.charAt(0).toUpperCase() + domain.slice(1);

    return company;
  }

  createDefaultUser(): User {
    return {
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      isServiceAgent: false
    };
  }
}

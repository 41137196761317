import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '../environment.provider';
import { Observable, shareReplay } from 'rxjs';
import { API_OPERATION_CONTEXT } from '../mock-api.interceptor';

export interface StorageAccount {
  name: string;
  resourceGroupName: string;
  location: string;
  type: string;
  accessTier: string;
  keys: StorageAccountKey[];
}

export interface StorageAccountKey {
  name: string;
  value: string;
  permissions: string;
}

@Injectable({
  providedIn: 'root'
})
export class StorageAccountsService {
  private cache: Record<string, Observable<StorageAccount>> = {};

  constructor(
    @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
    private readonly http: HttpClient
  ) {}

  /**
   * Fetches the storage account associated with a given resource group.
   * Caches the response to ensure that for each resource group, the HTTP
   * request is made only once, and subsequent calls with the same
   * resource group name return the cached response.
   */
  getStorageAccountByResourceGroup(
    resourceGroupName: string
  ): Observable<StorageAccount> {
    if (!this.cache[resourceGroupName]) {
      const url = `${this.environment.cspBackendUrl}/api/storage-accounts/${resourceGroupName}`;

      this.cache[resourceGroupName] = this.http
        .get<StorageAccount>(url, {
          context: new HttpContext().set(
            API_OPERATION_CONTEXT,
            'get-storage-account'
          )
        })
        // The shareReplay(1) operator ensures that the last emitted
        // value is cached and replayed to any new subscribers.
        .pipe(shareReplay(1));
    }

    return this.cache[resourceGroupName];
  }
}

<si-landing-page
  heading="Cloud Service Portal"
  [loginAlert]="loginAlert"
  copyright="© Siemens 2023"
  subheading="Process Industry Lifecycle Services"
  [subtitle]="'APP.CLAIM' | translate"
  backgroundImageUrl="./assets/images/landing-page-skyscrapers.jpg"
  [links]="[
    {
      title: 'Corporate information',
      href: 'http://www.siemens.com/corporate-information'
    },
    { title: 'Privacy policy', href: 'http://www.siemens.com/privacy-notice' },
    { title: 'Terms of use', href: 'http://www.siemens.com/terms-of-use' }
  ]"
  [availableLanguages]="availableLanguages"
>
  <button
    type="submit"
    [disabled]="loginStarted"
    class="btn btn-primary w-100"
    (click)="onLogin()"
  >
    {{ 'APP.LOGIN' | translate }}
  </button>
  <button
    type="submit"
    [disabled]="loginStarted"
    class="btn btn-secondary w-100 mt-5"
    (click)="onSignUp(signUpModalTmpl)"
  >
    {{ 'APP.SIGN_UP' | translate }}
  </button>
</si-landing-page>

<ng-template #signUpModalTmpl let-modalRef="modalRef">
  <app-sign-up-form (close)="modalRef.hide()"></app-sign-up-form>
</ng-template>

import {
  Department,
  VirtualMachine
} from '../../../../services/virtual-machines.service';
import { User } from '../../user.service';

export default function getRdpFileContent(
  virtualMachine: VirtualMachine,
  user: User
): string {
  const isLmsDepartment = virtualMachine.department === Department.LMS;
  const username = getUsername(user, isLmsDepartment);

  return `
screen mode id:i:2
use multimon:i:0
displayconnectionbar:i:1
enableworkspacereconnect:i:0
disable wallpaper:i:0
allow font smoothing:i:1
allow desktop composition:i:0
disable menu anims:i:1
bitmapcachepersistenable:i:1
full address:s:${virtualMachine.privateIpAddress}
audiomode:i:0
redirectprinters:i:0
redirectcomports:i:0
redirectsmartcards:i:0
redirectclipboard:i:1
redirectposdevices:i:0
authentication level:i:2
prompt for credentials:i:0
negotiate security layer:i:1
remoteapplicationmode:i:0
gatewayhostname:s:eu.siemens-pils.com
gatewayusagemethod:i:1
gatewaycredentialssource:i:0
gatewayprofileusagemethod:i:1
promptcredentialonce:i:${isLmsDepartment ? '1' : '0'}
gatewaybrokeringtype:i:0
use redirection server name:i:0
rdgiskdcproxy:i:0
drivestoredirect:s:*
username:s:${username}
alternate full address:s:${virtualMachine.privateIpAddress}
`.trim();
}

function getUsername(user: User, isLmsUser: boolean): string {
  if (!isLmsUser) {
    return 'vmadmin';
  }

  if (user.firstName.includes(' ')) {
    // The user has two first names e.g. "Walter Simon" so we can't tell which one is used for the AD username.
    return '';
  }

  // The user is an LMS user with a single first name.
  const { firstName, lastName } = user;

  const initial = firstName.charAt(0).toUpperCase();
  const surname = lastName.replace(/\s/g, '');

  return `LMS\\${initial}${surname}`; // LMS\MMustermann
}

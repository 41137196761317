import { ErrorHandler, Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class RefreshTokenErrorHandler extends ErrorHandler {
  constructor(private readonly authService: AuthService) {
    super();
  }

  /**
   * Logs out the user and redirects to the landing page if the error is related to an
   * expired or invalid refresh token. Other errors are handled by the base implementation.
   */
  override handleError(error: Error): void {
    if (error.message === 'Unknown or invalid refresh token.') {
      this.authService.logout({
        logoutParams: {
          // Include a query parameter in the redirect URL to provide
          // information for the landing page to display an appropriate alert.
          returnTo: `${window.location.origin}/#/landing?expiredSession=true`
        }
      });
    } else {
      // Treat all other errors as before.
      super.handleError(error);
    }
  }
}

<div class="modal-header">
  <h4 class="modal-title" id="sample-modal-title">Sign Up (Siemens ID)</h4>
  <button
    type="button"
    class="btn btn-circle btn-sm btn-ghost element-cancel"
    aria-label="Close modal"
    (click)="onClose()"
  ></button>
</div>
<div class="modal-body">
  <p>
    To sign up for
    <a
      href="https://cdn.login.siemens.com/id/siemens_id/index.html"
      target="_blank"
    >
      Siemens ID</a
    >, please complete the form below with your accurate information:
  </p>

  <form [formGroup]="signUpForm">
    <si-form-item label="First Name" [required]="true">
      <div class="form-control-has-icon w-100">
        <input
          type="text"
          id="firstName"
          class="form-control"
          autocomplete="off"
          formControlName="firstName"
        />
        <div *ngIf="firstName.errors?.['required']" class="invalid-feedback">
          A value is required.
        </div>
        <div *ngIf="firstName.errors?.['minlength']" class="invalid-feedback">
          The value must be at least two characters long.
        </div>
      </div>
    </si-form-item>

    <si-form-item label="Last Name" [required]="true">
      <div class="form-control-has-icon w-100">
        <input
          type="text"
          id="lastName"
          class="form-control"
          autocomplete="off"
          formControlName="lastName"
        />
        <div *ngIf="lastName.errors?.['required']" class="invalid-feedback">
          A value is required.
        </div>
        <div *ngIf="lastName.errors?.['minlength']" class="invalid-feedback">
          The value must be at least two characters long.
        </div>
      </div>
    </si-form-item>

    <si-form-item label="Email" [required]="true">
      <div class="form-control-has-icon w-100">
        <input
          id="email"
          type="email"
          autocomplete="off"
          class="form-control"
          formControlName="email"
        />

        <div *ngIf="email.errors?.['required']" class="invalid-feedback">
          A value is required.
        </div>
        <div *ngIf="email.errors?.['email']" class="invalid-feedback">
          The value must be a valid email address.
        </div>
        <div *ngIf="email.errors?.['forbiddenEmail']" class="invalid-feedback">
          Forbidden Email (@siemens.com). Siemens employees should use MyID, not
          Siemens ID, for login. The latter is for external customers.
        </div>
      </div>
    </si-form-item>

    <si-form-item label="Password" [required]="true">
      <div class="form-control-has-icon w-100">
        <si-password-strength>
          <input
            id="password"
            (focus)="showPasswordHints = true"
            type="password"
            formControlName="password"
            class="form-control"
            [siPasswordStrength]="{
              minLength: 12,
              uppercase: true,
              lowercase: true,
              digits: true,
              special: true
            }"
          />
        </si-password-strength>
        <div class="password-hints">
          <p class="mt-5 mb-2">Password must meet the following criteria:</p>
          <ul class="mb-0">
            <li
              [class.passed]="password.errors?.['password'].hasMinLength || !password.errors"
            >
              At least 12 characters in length
            </li>
            <li
              [class.passed]="password.errors?.['password'].hasLowerCase  || !password.errors"
            >
              Contains at least one lower case letter
            </li>
            <li
              [class.passed]="password.errors?.['password'].hasUpperCase  || !password.errors"
            >
              Contains at least one upper case letter
            </li>
            <li
              [class.passed]="password.errors?.['password'].hasNumber  || !password.errors"
            >
              Contains at least one number
            </li>
            <li
              [class.passed]="password.errors?.['password'].hasSpecialChar  || !password.errors"
            >
              Contains at least one special character (e.g. !@#$_%^&?*)
            </li>
          </ul>
        </div>
      </div>
    </si-form-item>

    <si-form-item label="Accept Terms" [required]="true">
      <div class="form-check d-flex">
        <input
          type="checkbox"
          class="form-check-input"
          id="acceptTerms"
          formControlName="acceptTerms"
        />
        <label class="form-check-label" for="acceptTerms">
          I agree to the
          <a
            href="https://www.siemens.com/global/en/general/terms-of-use.html"
            target="_blank"
            >terms and conditions</a
          >
          and consent to the processing of my personal data as described
          <a
            href="https://cdn.login.siemens.com/public/dp_consent.pdf"
            target="_blank"
            >here</a
          >.</label
        >
      </div>
    </si-form-item>

    <!-- Sign Up Notifications -->
    <si-inline-notification
      *ngIf="signUpErrorCode === 'user_exists'"
      severity="danger"
      [message]="'The user ' + email.value + ' already exists.'"
    ></si-inline-notification>
    <si-inline-notification
      *ngIf="signedUpSuccessfully"
      severity="success"
      message="Successfully signed up for Siemens ID."
    ></si-inline-notification>
    <si-inline-notification
      *ngIf="signedUpSuccessfully"
      severity="info"
      message="Please verify your email address before continuing."
    ></si-inline-notification>
  </form>
</div>
<div class="modal-footer">
  <div class="w-100" *ngIf="isSigningIn">
    <app-progress-bar
      [millisUntilFull]="2000"
      label="Submitting form..."
    ></app-progress-bar>
  </div>
  <button type="button" (click)="onClose()" class="btn btn-secondary">
    Cancel
  </button>
  <button
    type="button"
    (click)="onSubmit()"
    [disabled]="!signUpForm.valid || isSigningIn"
    class="btn btn-primary"
  >
    Submit
  </button>
</div>

import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpContextToken,
  HttpHeaders
} from '@angular/common/http';
import { catchError, delay, of, throwError, concat, timer } from 'rxjs';
import {
  SiemensIdSignUpUser,
  SignUpError
} from './services/siemens-id-sign-up.service';
import {
  Department,
  VirtualMachine,
  VirtualMachineStatus
} from './services/virtual-machines.service';
import { OperationStatus } from './services/operation.service';
import { ENVIRONMENT_TOKEN, Environment } from './environment.provider';
import { StorageAccount } from './services/storage-accounts.service';

export const API_OPERATION_CONTEXT = new HttpContextToken<
  | 'get-vms'
  | 'get-vm'
  | 'start'
  | 'stop'
  | 'restart'
  | 'signup'
  | 'validate-access-token'
  | 'get-operation'
  | 'reset-ip-address'
  | 'change-admin-password'
  | 'request-vm'
  | 'get-storage-account'
  | undefined
>(() => undefined);

export const VM_NAME_CONTEXT = new HttpContextToken<string>(() => '');

@Injectable()
export class MockApiInterceptor implements HttpInterceptor {
  siemensIdUsers: SiemensIdSignUpUser[] = [];

  private storageAccount: StorageAccount = {
    name: 'customerldev01strg',
    resourceGroupName: 'customer-ldev01-rg',
    accessTier: 'Hot',
    keys: [
      {
        name: 'key1',
        value: 'foobar',
        permissions: 'FULL'
      },
      {
        name: 'key2',
        value: 'barfoo',
        permissions: 'FULL'
      }
    ],
    location: 'westeurope',
    type: 'StorageV2'
  };

  private virtualMachines: VirtualMachine[] = [
    {
      name: 'customer-linf01-vm-LMSDC01-003',
      computerName: 'LMSDC01',
      resourceGroupName: 'customer-linf01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2021-08-25T09:11:02.608Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_B2ms',
      privateIpAddress: '10.128.6.6',
      crspLink: null
    },
    {
      name: 'customer-linf01-vm-LMSSASJD01-001',
      computerName: 'LMSSASJD01',
      resourceGroupName: 'customer-linf01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2021-08-23T09:03:23.726Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_D2s_v3',
      privateIpAddress: '10.128.6.4',
      crspLink: null
    },
    {
      name: 'customer-linf01-vm-LMSSASPM01-002',
      computerName: 'LMSSASPM01',
      resourceGroupName: 'customer-linf01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2021-08-23T09:04:28.930Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_D2s_v3',
      privateIpAddress: '10.128.6.5',
      crspLink: null
    },
    {
      name: 'customer-ldev01-vm-LMSDEV01-011',
      computerName: 'LMSDEV01',
      resourceGroupName: 'customer-ldev01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2022-02-24T06:48:57.775Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_F8s_v2',
      privateIpAddress: '10.128.7.4',
      crspLink: null
    },
    {
      name: 'customer-ldev01-vm-LMSDEV02-002',
      computerName: 'LMSDEV02',
      resourceGroupName: 'customer-ldev01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2021-08-31T07:15:09.265Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_F8s_v2',
      privateIpAddress: '10.128.7.5',
      crspLink: null
    },
    {
      name: 'customer-ldev01-vm-LMSDEV03-003',
      computerName: 'LMSDEV03',
      resourceGroupName: 'customer-ldev01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2021-09-01T09:15:19.645Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_F8s_v2',
      privateIpAddress: '10.128.7.6',
      crspLink: null
    },
    {
      name: 'customer-ldev01-vm-LMSDEV04-004',
      computerName: 'LMSDEV04',
      resourceGroupName: 'customer-ldev01-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-09-07T11:25:39.114Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_F8s_v2',
      privateIpAddress: '10.128.7.7',
      crspLink: null
    },
    {
      name: 'customer-ldev01-vm-LMSDEV05-005',
      computerName: 'LMSDEV05',
      resourceGroupName: 'customer-ldev01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2021-09-13T10:02:53.226Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_F8s_v2',
      privateIpAddress: '10.128.7.8',
      crspLink: null
    },
    {
      name: 'customer-ldev01-vm-LMSDEV06-006',
      computerName: 'LMSDEV06',
      resourceGroupName: 'customer-ldev01-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-09-14T09:11:44.551Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_F8s_v2',
      privateIpAddress: '10.128.7.9',
      crspLink: null
    },
    {
      name: 'customer-ldev01-vm-LMSDEV07-007',
      computerName: 'LMSDEV07',
      resourceGroupName: 'customer-ldev01-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-09-16T08:07:45.925Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_F8s_v2',
      privateIpAddress: '10.128.7.10',
      crspLink: null
    },
    {
      name: 'customer-ldev01-vm-LMSDEV08-008',
      computerName: 'LMSDEV08',
      resourceGroupName: 'customer-ldev01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2021-09-27T06:56:53.697Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_F8s_v2',
      privateIpAddress: '10.128.7.11',
      crspLink: null
    },
    {
      name: 'customer-ldev01-vm-LMSDEV09-009',
      computerName: 'LMSDEV09',
      resourceGroupName: 'customer-ldev01-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-09-27T13:15:50.018Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_F8s_v2',
      privateIpAddress: '10.128.7.12',
      crspLink: null
    },
    {
      name: 'customer-ldev01-vm-LMSDEV10-012',
      computerName: 'LMSDEV10',
      resourceGroupName: 'customer-ldev01-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2022-07-08T06:49:04.501Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D2s_v3',
      privateIpAddress: '10.128.7.14',
      crspLink: null
    },
    {
      name: 'customer-ldev01-vm-LMSLWS01-010',
      computerName: 'LMSLWS01',
      resourceGroupName: 'customer-ldev01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2022-02-02T10:55:34.139Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_D2s_v3',
      privateIpAddress: '10.128.7.13',
      crspLink: null
    },
    {
      name: 'customer-ldev01-vm-SASDEV01-013',
      computerName: 'SASDEV01',
      resourceGroupName: 'customer-ldev01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2022-08-08T12:26:45.314Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_F4s_v2',
      privateIpAddress: '10.128.7.15',
      crspLink: null
    },
    {
      name: 'customer-lpro01-vm-LMS001-001',
      computerName: 'LMS001',
      resourceGroupName: 'customer-lpro01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2021-09-02T08:40:33.708Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_D2s_v3',
      privateIpAddress: '10.128.11.4',
      crspLink: null
    },
    {
      name: 'customer-sinf01-vm-sinf01001-001',
      computerName: 'sinf01001',
      resourceGroupName: 'customer-sinf01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2021-09-20T11:59:10.002Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_B2s',
      privateIpAddress: '10.128.14.4',
      crspLink: null
    },
    {
      name: 'customer-lpro02-vm-LMS002-001',
      computerName: 'LMS002',
      resourceGroupName: 'customer-lpro02-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2021-09-30T06:10:14.173Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_F16s_v2',
      privateIpAddress: '10.128.17.4',
      crspLink: null
    },
    {
      name: 'customer-lpis01-vm-PIService-001',
      computerName: 'PIService',
      resourceGroupName: 'customer-lpis01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2021-10-01T06:39:33.127Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_D2s_v3',
      privateIpAddress: '10.128.18.4',
      crspLink: null
    },
    {
      name: 'customer-lpro03-vm-LMS003-001',
      computerName: 'LMS003',
      resourceGroupName: 'customer-lpro03-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2021-10-07T06:29:12.944Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_F8s_v2',
      privateIpAddress: '10.128.19.4',
      crspLink: null
    },
    {
      name: 'customer-lpro04-vm-LMS004-001',
      computerName: 'LMS004',
      resourceGroupName: 'customer-lpro04-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2021-12-01T09:04:58.048Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_F8s_v2',
      privateIpAddress: '10.128.23.4',
      crspLink: null
    },
    {
      name: 'customer-sapc03-vm-STRES01-006',
      computerName: 'STRES01',
      resourceGroupName: 'customer-sapc03-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-09T12:56:59.243Z',
      shutdownDate: '2022-12-09T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4s_v4',
      privateIpAddress: '10.128.26.4',
      crspLink: null
    },
    {
      name: 'customer-sapc03-vm-STRES02-007',
      computerName: 'STRES02',
      resourceGroupName: 'customer-sapc03-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-09T12:57:22.040Z',
      shutdownDate: '2022-12-09T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4s_v4',
      privateIpAddress: '10.128.26.5',
      crspLink: null
    },
    {
      name: 'customer-sapc03-vm-STROS01-008',
      computerName: 'STROS01',
      resourceGroupName: 'customer-sapc03-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-09T12:57:47.665Z',
      shutdownDate: '2022-12-09T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D2s_v4',
      privateIpAddress: '10.128.26.6',
      crspLink: null
    },
    {
      name: 'customer-sapc03-vm-STRTS01-009',
      computerName: 'STRTS01',
      resourceGroupName: 'customer-sapc03-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-09T12:58:13.571Z',
      shutdownDate: '2022-12-09T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D2s_v4',
      privateIpAddress: '10.128.26.7',
      crspLink: null
    },
    {
      name: 'customer-sapc03-vm-STRVC01-005',
      computerName: 'STRVC01',
      resourceGroupName: 'customer-sapc03-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-02T09:58:30.954Z',
      shutdownDate: '2022-12-02T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4s_v4',
      privateIpAddress: '10.128.26.8',
      crspLink: null
    },
    {
      name: 'customer-lpro07-vm-LMS00701-001',
      computerName: 'LMS00701',
      resourceGroupName: 'customer-lpro07-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-14T10:27:44.675Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4s_v4',
      privateIpAddress: '10.128.28.4',
      crspLink: null
    },
    {
      name: 'customer-lpro07-vm-LMS00702-002',
      computerName: 'LMS00702',
      resourceGroupName: 'customer-lpro07-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-14T10:30:28.832Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4ds_v4',
      privateIpAddress: '10.128.28.5',
      crspLink: null
    },
    {
      name: 'customer-lpro08-vm-LMS00801-001',
      computerName: 'LMS00801',
      resourceGroupName: 'customer-lpro08-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-14T11:36:31.637Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4s_v4',
      privateIpAddress: '10.128.29.4',
      crspLink: null
    },
    {
      name: 'customer-sapc04-vm-AMMES01-001',
      computerName: 'AMMES01',
      resourceGroupName: 'customer-sapc04-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-15T07:41:45.949Z',
      shutdownDate: '2022-12-15T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4s_v4',
      privateIpAddress: '10.128.27.4',
      crspLink: null
    },
    {
      name: 'customer-sapc04-vm-AMMES02-002',
      computerName: 'AMMES02',
      resourceGroupName: 'customer-sapc04-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-15T07:42:09.980Z',
      shutdownDate: '2022-12-15T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4s_v4',
      privateIpAddress: '10.128.27.5',
      crspLink: null
    },
    {
      name: 'customer-sapc04-vm-AMMOS01-003',
      computerName: 'AMMOS01',
      resourceGroupName: 'customer-sapc04-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-15T07:42:46.012Z',
      shutdownDate: '2022-12-15T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D2s_v4',
      privateIpAddress: '10.128.27.6',
      crspLink: null
    },
    {
      name: 'customer-sapc04-vm-AMMTS01-004',
      computerName: 'AMMTS01',
      resourceGroupName: 'customer-sapc04-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-15T07:43:23.371Z',
      shutdownDate: '2022-12-15T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D2s_v4',
      privateIpAddress: '10.128.27.7',
      crspLink: null
    },
    {
      name: 'customer-sapc04-vm-AMMVC01-005',
      computerName: 'AMMVC01',
      resourceGroupName: 'customer-sapc04-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-16T09:50:48.036Z',
      shutdownDate: '2022-12-16T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4s_v4',
      privateIpAddress: '10.128.27.8',
      crspLink: null
    },
    {
      name: 'customer-sapc05-vm-UTLES01-001',
      computerName: 'UTLES01',
      resourceGroupName: 'customer-sapc05-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-15T12:23:17.986Z',
      shutdownDate: '2022-12-15T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4s_v4',
      privateIpAddress: '10.128.30.4',
      crspLink: null
    },
    {
      name: 'customer-sapc05-vm-UTLES02-002',
      computerName: 'UTLES02',
      resourceGroupName: 'customer-sapc05-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-15T12:23:48.548Z',
      shutdownDate: '2022-12-15T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4s_v4',
      privateIpAddress: '10.128.30.5',
      crspLink: null
    },
    {
      name: 'customer-sapc05-vm-UTLOS01-003',
      computerName: 'UTLOS01',
      resourceGroupName: 'customer-sapc05-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-15T12:24:13.720Z',
      shutdownDate: '2022-12-15T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D2s_v4',
      privateIpAddress: '10.128.30.6',
      crspLink: null
    },
    {
      name: 'customer-sapc05-vm-UTLTS01-004',
      computerName: 'UTLTS01',
      resourceGroupName: 'customer-sapc05-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-15T12:25:18.705Z',
      shutdownDate: '2022-12-15T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D2s_v4',
      privateIpAddress: '10.128.30.7',
      crspLink: null
    },
    {
      name: 'customer-sapc05-vm-UTLVC01-005',
      computerName: 'UTLVC01',
      resourceGroupName: 'customer-sapc05-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2021-12-15T12:25:48.908Z',
      shutdownDate: '2022-12-15T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4s_v4',
      privateIpAddress: '10.128.30.8',
      crspLink: null
    },
    {
      name: 'customer-lpro05-vm-LMS005-001',
      computerName: 'LMS005',
      resourceGroupName: 'customer-lpro05-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2021-12-20T08:03:52.238Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_F8s_v2',
      privateIpAddress: '10.128.31.4',
      crspLink: null
    },
    {
      name: 'customer-lpro06-vm-LMS006-001',
      computerName: 'LMS006',
      resourceGroupName: 'customer-lpro06-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2021-12-21T13:44:28.702Z',
      shutdownDate: '9999-12-31T00:00:00.000Z',
      department: Department.LMS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_F8s_v2',
      privateIpAddress: '10.128.32.4',
      crspLink: null
    },
    {
      name: 'customer-sapc06-vm-HYDES01-001',
      computerName: 'HYDES01',
      resourceGroupName: 'customer-sapc06-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2022-01-24T12:05:28.604Z',
      shutdownDate: '2023-01-24T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4s_v4',
      privateIpAddress: '10.128.33.4',
      crspLink: null
    },
    {
      name: 'customer-sapc06-vm-HYDES02-002',
      computerName: 'HYDES02',
      resourceGroupName: 'customer-sapc06-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2022-01-24T12:07:02.151Z',
      shutdownDate: '2023-01-24T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4s_v4',
      privateIpAddress: '10.128.33.5',
      crspLink: null
    },
    {
      name: 'customer-sapc06-vm-HYDOS01-003',
      computerName: 'HYDOS01',
      resourceGroupName: 'customer-sapc06-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2022-01-24T12:07:58.463Z',
      shutdownDate: '2023-01-24T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D2s_v4',
      privateIpAddress: '10.128.33.6',
      crspLink: null
    },
    {
      name: 'customer-sapc06-vm-HYDTS01-004',
      computerName: 'HYDTS01',
      resourceGroupName: 'customer-sapc06-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2022-01-24T12:08:34.667Z',
      shutdownDate: '2023-01-24T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D2s_v4',
      privateIpAddress: '10.128.33.7',
      crspLink: null
    },
    {
      name: 'customer-sapc06-vm-HYDVC01-005',
      computerName: 'HYDVC01',
      resourceGroupName: 'customer-sapc06-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2022-01-24T12:09:10.260Z',
      shutdownDate: '2023-01-24T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4s_v5',
      privateIpAddress: '10.128.33.8',
      crspLink: null
    },
    {
      name: 'customer-snit01-vm-snit01001-001',
      computerName: 'snit01001',
      resourceGroupName: 'customer-snit01-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2022-05-30T14:15:14.961Z',
      shutdownDate: '2023-06-01T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D2s_v3',
      privateIpAddress: '10.128.34.4',
      crspLink: null
    },
    {
      name: 'customer-sevo01-vm-JumpHost01-001',
      computerName: 'JumpHost01',
      resourceGroupName: 'customer-sevo01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2022-09-21T09:37:11.804Z',
      shutdownDate: '2023-10-01T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_D2s_v5',
      privateIpAddress: '10.128.37.4',
      crspLink: null
    },
    {
      name: 'customer-sevo01-vm-JumpHost02-002',
      computerName: 'JumpHost02',
      resourceGroupName: 'customer-sevo01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2022-09-21T09:38:52.773Z',
      shutdownDate: '2023-10-01T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_D2s_v5',
      privateIpAddress: '10.128.37.5',
      crspLink: null
    },
    {
      name: 'customer-stes01-vm-PCS7ES01-004',
      computerName: 'PCS7ES01',
      resourceGroupName: 'customer-stes01-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2022-11-14T13:45:09.423Z',
      shutdownDate: '2023-02-02T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_FX4mds',
      privateIpAddress: '10.128.38.4',
      crspLink: null
    },
    {
      name: 'customer-stes01-vm-PCS7ES02-003',
      computerName: 'PCS7ES02',
      resourceGroupName: 'customer-stes01-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2022-11-14T07:20:44.029Z',
      shutdownDate: '2023-02-02T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_FX4mds',
      privateIpAddress: '10.128.38.6',
      crspLink: null
    },
    {
      name: 'customer-stes01-vm-SIMIT01-002',
      computerName: 'SIMIT01',
      resourceGroupName: 'customer-stes01-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2022-11-11T10:11:43.675Z',
      shutdownDate: '2023-01-30T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_D4s_v5',
      privateIpAddress: '10.128.38.5',
      crspLink: null
    },
    {
      name: 'customer-samg01-vm-PCS7ES01-001',
      computerName: 'PCS7ES01',
      resourceGroupName: 'customer-samg01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2023-01-20T12:51:22.247Z',
      shutdownDate: '2023-06-24T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_D4s_v5',
      privateIpAddress: '10.128.39.4',
      crspLink: null
    },
    {
      name: 'customer-samg01-vm-PCS7ES02-002',
      computerName: 'PCS7ES02',
      resourceGroupName: 'customer-samg01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2023-01-20T12:52:47.201Z',
      shutdownDate: '2023-06-24T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_D4s_v5',
      privateIpAddress: '10.128.39.5',
      crspLink: null
    },
    {
      name: 'customer-sdig01-vm-PCS7ES01-001',
      computerName: 'PCS7ES01',
      resourceGroupName: 'customer-sdig01-rg',
      status: VirtualMachineStatus.Stopped,
      createdDate: '2023-02-27T08:38:43.708Z',
      shutdownDate: '2023-08-01T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      size: 'Standard_E4s_v5',
      privateIpAddress: '10.128.44.4',
      crspLink: null
    },
    {
      name: 'customer-scsp01-vm-TestVM01-001',
      computerName: 'TestVM01',
      resourceGroupName: 'customer-scsp01-rg',
      status: VirtualMachineStatus.Running,
      createdDate: '2023-03-03T10:04:35.474Z',
      shutdownDate: '2023-03-17T00:00:00.000Z',
      department: Department.SiPaaS,
      location: 'westeurope',
      operatingSystem: 'Windows',
      diskSizeGB: 127,
      size: 'Standard_D4s_v5',
      privateIpAddress: '10.128.45.4',
      crspLink: 'https://google.com'
    }
  ];

  private operationId = 1;
  private operations: Record<number, { status: OperationStatus }> = {};

  constructor(
    @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment
  ) {}

  intercept(request: HttpRequest<unknown>, next: any): any {
    const context = request.context.get(API_OPERATION_CONTEXT);

    switch (context) {
      case 'validate-access-token':
        return this.mockValidateAccessToken();
      case 'get-storage-account':
        return this.mockGetStorageAccount();
      case 'get-vms':
        return this.mockGetVirtualMachines();
      case 'get-vm':
        const vmName = request.context.get(VM_NAME_CONTEXT);
        return this.mockGetVirtualMachine(vmName);
      case 'start':
        return this.mockStartVirtualMachine();
      case 'stop':
        return this.mockStopVirtualMachine();
      case 'restart':
        return this.mockRestartStartVirtualMachine();
      case 'signup':
        return this.mockSignUpSiemensIdUser(request);
      case 'reset-ip-address':
        return this.mockResetIpAddress();
      case 'change-admin-password':
        return this.mockChangeAdminPassword();
      case 'request-vm':
        return this.mockRequestVirtualMachine();
      case 'get-operation':
        return this.mockGetOperation(request);
      default:
        return next.handle(request);
    }
  }

  private mockGetStorageAccount() {
    return of(new HttpResponse({ body: this.storageAccount })).pipe(delay(500));
  }

  private mockRequestVirtualMachine() {
    return of(new HttpResponse()).pipe(delay(300));
  }

  private mockGetOperation(request: HttpRequest<unknown>) {
    const operationIdPattern = /\/operations\/(\d+)/;
    const [, operationId] = operationIdPattern.exec(
      request.url
    ) as RegExpExecArray;
    const operation = this.operations[parseInt(operationId)];

    return of(new HttpResponse({ body: operation })).pipe(delay(300));
  }

  private generateOperation() {
    const operationId = this.operationId++;
    this.operations[operationId] = { status: OperationStatus.InProgress };

    // Set the operation to "DONE" after 10 seconds.
    setTimeout(() => {
      this.operations[operationId].status = OperationStatus.Done;
    }, 10000);

    return operationId;
  }

  private mockSignUpSiemensIdUser(request: HttpRequest<unknown>) {
    const user = request.body as SiemensIdSignUpUser;

    const userAlreadyExists =
      this.siemensIdUsers.find((u) => u.email === user.email) !== undefined;

    if (userAlreadyExists) {
      const signUpError: SignUpError = {
        code: 'user_exists',
        description: 'The user already exists.',
        name: 'BadRequestError',
        statusCode: 400
      };
      return concat(
        timer(300),
        throwError(() => new HttpErrorResponse({ error: signUpError }))
      ).pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
    } else {
      this.siemensIdUsers.push(user);
      return of(new HttpResponse()).pipe(delay(2000));
    }
  }

  private mockValidateAccessToken() {
    return of(new HttpResponse()).pipe(delay(300));
  }

  private mockGetVirtualMachines() {
    return of(new HttpResponse({ body: this.virtualMachines })).pipe(
      delay(1000)
    );
  }

  private mockGetVirtualMachine(vmName: string) {
    return of(
      new HttpResponse({
        body: this.virtualMachines.find((vm) => vm.name === vmName)
      })
    ).pipe(delay(500));
  }

  private mockStartVirtualMachine() {
    return this.startOperation();
  }

  private mockStopVirtualMachine() {
    return this.startOperation();
  }

  private mockRestartStartVirtualMachine() {
    return this.startOperation();
  }

  private mockResetIpAddress() {
    return this.startOperation();
  }

  private mockChangeAdminPassword() {
    return this.startOperation();
  }

  private startOperation() {
    const operationId = this.generateOperation();
    return of(
      new HttpResponse({
        status: 202,
        headers: new HttpHeaders({
          Location: `${this.environment.cspBackendUrl}/api/operations/${operationId}`
        })
      })
    ).pipe(delay(200));
  }
}

import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapitalizePipe } from './capitalize.pipe';
import { LoadingDotsComponent } from './loading-dots.component';
import { SharedSimplElementModule } from './shared-simpl-element.module';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { DiffFromCurrentPipe } from './diff-from-current.pipe';
import { FriendlyDatePipe } from './friendly-date.pipe';
import { FilterHighlightComponent } from './filter-highlight/filter-highlight.component';

@NgModule({
  imports: [SharedSimplElementModule],
  declarations: [
    LoadingDotsComponent,
    CapitalizePipe,
    DiffFromCurrentPipe,
    ProgressBarComponent,
    FriendlyDatePipe,
    FilterHighlightComponent
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    SharedSimplElementModule,
    LoadingDotsComponent,
    ReactiveFormsModule,
    FormsModule,
    DiffFromCurrentPipe,
    CapitalizePipe,
    ProgressBarComponent,
    FriendlyDatePipe,
    FilterHighlightComponent
  ],
  providers: [DatePipe, FriendlyDatePipe]
})
export class SharedModule {}

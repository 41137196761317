export const environment = {
  isDeployed: true,
  cspBackendUrl: 'https://csp-backend.apps.case.siemens.cloud',
  auth: {
    auth0: {
      domain: 'siemens-00169.eu.auth0.com',
      clientId: 'VhuWZbo9TGMryolWdB7jrWmqFeEyvPYx',
      authorizationParams: {
        redirect_uri: window.location.origin,
        connection: 'main-tenant-oidc',
        audience: 'cloud-service-portal-backend'
      }
    },
    siemensIdSignUp: {
      clientId: 'P8EJrZaoWPRSoBkCirnfN4yRQQDZmZLF',
      url: 'https://login.siemens.com/dbconnections/signup'
    }
  }
};

<si-form-item [label]="key.name">
  <div class="form-control-has-icon w-100">
    <div class="key-container">
      <input
        [readOnly]="true"
        [type]="isKeyVisible ? 'text' : 'password'"
        class="form-control"
        [value]="key.value"
      />

      <button
        type="button"
        class="btn btn-circle btn-sm btn-ghost element-copy"
        (click)="onCopy()"
      ></button>

      <button
        type="button"
        class="btn btn-circle btn-sm btn-ghost"
        [class.element-show]="!isKeyVisible"
        [class.element-hide]="isKeyVisible"
        (click)="isKeyVisible = !isKeyVisible"
      ></button>
    </div>
  </div>
</si-form-item>

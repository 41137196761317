import { ValidatorFn, FormControl, ValidationErrors } from '@angular/forms';

export default function passwordValidator(): ValidatorFn | any {
  return (control: FormControl): ValidationErrors | null => {
    const value = control.value as string;

    const hasMinLength = value.length >= 12;
    const hasLowerCase = /[a-z]/.test(value);
    const hasUpperCase = /[A-Z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSpecialChar = /[!@#$%\^&*\?]/.test(value);

    if (
      hasLowerCase &&
      hasUpperCase &&
      hasNumber &&
      hasSpecialChar &&
      hasMinLength
    ) {
      // Valid password.
      return null;
    } else {
      return {
        password: {
          hasMinLength,
          hasLowerCase,
          hasUpperCase,
          hasNumber,
          hasSpecialChar
        }
      };
    }
  };
}

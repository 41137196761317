import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  distinct,
  filter,
  first,
  interval,
  map,
  switchMap
} from 'rxjs';
import { ENVIRONMENT_TOKEN, Environment } from '../environment.provider';
import {
  API_OPERATION_CONTEXT,
  VM_NAME_CONTEXT
} from '../mock-api.interceptor';
import { Notification, NotificationsService } from './notifications.service';
import {
  Operation,
  OperationService,
  OperationStatus
} from './operation.service';

/**
 * Describes a Virtual Machine resource on Azure.
 */
export interface VirtualMachine {
  name: string;
  computerName: string;
  resourceGroupName: string;
  department: Department;
  status: VirtualMachineStatus;
  location: string;
  createdDate: string;
  shutdownDate: string;
  operatingSystem: string;
  size: string;
  privateIpAddress: string;

  // The disk size is missing for deallocated VMs.
  diskSizeGB?: number;

  // The crsp link will be `null` for vms where crsp is not enabled.
  crspLink: string | null;
}

export enum Department {
  LMS = 'LMS',
  SiPaaS = 'SIPAAS'
}

export type VirtualMachineAction =
  | 'start'
  | 'stop'
  | 'restart'
  | 'reset-ip-address'
  | 'change-admin-password';

/**
 * Represents the status of a virtual machine.
 */
export enum VirtualMachineStatus {
  Running = 'RUNNING',
  Stopped = 'STOPPED',
  Starting = 'STARTING',
  Stopping = 'STOPPING',
  Restarting = 'RESTARTING',
  Updating = 'UPDATING'
}

@Injectable({
  providedIn: 'root'
})
export class VirtualMachinesService {
  private readonly virtualMachinesSubject = new BehaviorSubject<
    VirtualMachine[]
  >([]);
  virtualMachines$ = this.virtualMachinesSubject.asObservable();
  resourceGroupNames$ = this.virtualMachines$.pipe(
    map((vms) => vms.map((vm) => vm.resourceGroupName)),
    distinct()
  );

  constructor(
    @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
    private readonly http: HttpClient,
    private readonly notificationsService: NotificationsService,
    private readonly operationService: OperationService
  ) {}

  /**
   * Fetches the list of virtual machines from the backend API and updates the virtualMachines$ observable.
   *
   * Sends an HTTP GET request to fetch the virtual machines and updates the virtualMachines$ observable
   * for the components to consume.
   */
  loadVirtualMachines(): void {
    const url = `${this.environment.cspBackendUrl}/api/virtual-machines`;

    this.http
      .get<VirtualMachine[]>(url, {
        context: new HttpContext().set(API_OPERATION_CONTEXT, 'get-vms')
      })
      .subscribe((vms) => this.virtualMachinesSubject.next(vms));
  }

  /**
   * Fetches the details of a specific virtual machine.
   *
   * Sends an HTTP GET request to retrieve the VM details based on the given virtual machine object.
   */
  getVirtualMachine(
    virtualMachine: VirtualMachine
  ): Observable<VirtualMachine> {
    return this.http.get<VirtualMachine>(
      `${this.environment.cspBackendUrl}/api/resource-groups/${virtualMachine.resourceGroupName}/virtual-machines/${virtualMachine.name}`,
      {
        context: new HttpContext()
          .set(API_OPERATION_CONTEXT, 'get-vm')
          .set(VM_NAME_CONTEXT, virtualMachine.name)
      }
    );
  }

  /**
   * Starts a virtual machine and updates its status.
   *
   * Sends an HTTP POST request to start the VM and initiates polling for status updates.
   */
  startVirtualMachine(virtualMachine: VirtualMachine): void {
    this.executeVirtualMachineActionAndUpdateStatus(
      virtualMachine,
      'start',
      VirtualMachineStatus.Running
    );
  }

  /**
   * Stops a virtual machine and updates its status.
   *
   * Sends an HTTP POST request to stop the VM and initiates polling for status updates.
   */
  stopVirtualMachine(virtualMachine: VirtualMachine): void {
    this.executeVirtualMachineActionAndUpdateStatus(
      virtualMachine,
      'stop',
      VirtualMachineStatus.Stopped
    );
  }

  /**
   * Restarts a virtual machine and updates its status.
   *
   * Sends an HTTP POST request to restart the VM and initiates polling for status updates.
   */
  restartVirtualMachine(virtualMachine: VirtualMachine): void {
    this.executeVirtualMachineActionAndUpdateStatus(
      virtualMachine,
      'restart',
      VirtualMachineStatus.Running
    );
  }

  /**
   * Resets the private ip address of a virtual machine and updates its status.
   *
   * Sends an HTTP POST request to reset the ip address of the VM and initiates polling for status updates.
   */
  resetIpAddress(virtualMachine: VirtualMachine): void {
    this.executeVirtualMachineActionAndUpdateStatus(
      virtualMachine,
      'reset-ip-address',
      VirtualMachineStatus.Running
    );
  }

  changeAdminPassword(
    virtualMachine: VirtualMachine,
    newPassword: string
  ): void {
    this.executeVirtualMachineActionAndUpdateStatus(
      virtualMachine,
      'change-admin-password',
      VirtualMachineStatus.Running,
      { password: newPassword }
    );
  }

  /**
   * Executes the specified action on the virtual machine, updates its status, and manages notifications.
   * Initiates the action on the given virtual machine, sets its status to 'Updating', and upon completion,
   * updates the target status. The method also handles notifications during the process.
   *
   * @param virtualMachine - The virtual machine object on which the action will be performed.
   * @param action - The action to perform on the virtual machine ('start', 'stop', 'restart', or 'reset-ip-address').
   * @param targetStatus - The status to set for the virtual machine after successfully completing the action.
   */
  private executeVirtualMachineActionAndUpdateStatus(
    virtualMachine: VirtualMachine,
    action: VirtualMachineAction,
    targetStatus: VirtualMachineStatus,
    requestBody = {}
  ): void {
    this.setVirtualMachineStatus(virtualMachine, VirtualMachineStatus.Updating);

    // Notify the application about the ongoing status change.
    // Store the notification ID so it can be updated later with the final status.
    const notificationId = this.addNotification(
      virtualMachine.computerName,
      action
    );

    const { name, resourceGroupName } = virtualMachine;
    const url = `${this.environment.cspBackendUrl}/api/resource-groups/${resourceGroupName}/virtual-machines/${name}/${action}`;
    this.http
      .post<void>(url, requestBody, {
        context: new HttpContext().set(API_OPERATION_CONTEXT, action),
        observe: 'response' // Tell HttpClient to return the full HttpResponse object, allowing us to access headers.
      })
      .pipe(
        switchMap((response) => {
          // The URL of the created operation, which contains the operation ID.
          // We use this operation ID to fetch the operation status by making GET requests to the specified URL.
          const operationUrl = response.headers.get('Location');
          if (!operationUrl) {
            throw new Error('Location header is missing in the response');
          }

          return this.pollForOperationCompletion(operationUrl);
        })
      )
      .subscribe(() => {
        const currentVms = this.virtualMachinesSubject.getValue();
        const updatedVms = currentVms.map((vm) =>
          vm.name === virtualMachine.name
            ? { ...vm, status: targetStatus }
            : { ...vm }
        );
        this.virtualMachinesSubject.next(updatedVms);
        this.addNotification(
          virtualMachine.computerName,
          action,
          notificationId
        );
      });
  }

  private setVirtualMachineStatus(
    virtualMachine: VirtualMachine,
    status: VirtualMachineStatus
  ): void {
    const currentVms = this.virtualMachinesSubject.getValue();
    virtualMachine.status = status;

    const initialUpdatedVms = currentVms.map((vm) =>
      vm.name === virtualMachine.name ? { ...virtualMachine } : { ...vm }
    );

    this.virtualMachinesSubject.next(initialUpdatedVms);
  }

  private pollForOperationCompletion(
    operationUrl: string
  ): Observable<Operation> {
    const pollInterval = 2000; // Poll every 2 seconds

    return interval(pollInterval).pipe(
      switchMap(() => this.operationService.getOperation(operationUrl)),
      filter((operation) => operation.status === OperationStatus.Done),
      first()
    );
  }

  private addNotification(
    vmComputerName: string,
    action: VirtualMachineAction,
    notificationToReplace = ''
  ): string {
    const notificationConfig = {
      start: {
        title: 'Starting virtual machine',
        completedTitle: 'Started',
        message: 'virtual machine'
      },
      stop: {
        title: 'Stopping virtual machine',
        completedTitle: 'Stopped',
        message: 'virtual machine'
      },
      restart: {
        title: 'Restarting virtual machine',
        completedTitle: 'Restarted',
        message: 'virtual machine'
      },
      'reset-ip-address': {
        title: 'Resetting IP address',
        completedTitle: 'IP address reset',
        message: 'for virtual machine'
      },
      'change-admin-password': {
        title: 'Changing Admin Password',
        completedTitle: 'Changed admin password',
        message: 'for virtual machine'
      }
    };

    const config = notificationConfig[action];
    const isCompleted = Boolean(notificationToReplace);
    const title = isCompleted ? config.completedTitle : config.title;
    const status: Notification['status'] = isCompleted ? 'success' : 'running';
    const message = `${title} ${config.message} "${vmComputerName}"${
      isCompleted ? '' : '...'
    }`;

    const notification: Notification = {
      title,
      message,
      status,
      notificationToReplace
    };

    return this.notificationsService.addNotification(notification);
  }
}

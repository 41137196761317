import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'diffFromCurrent' })
export class DiffFromCurrentPipe implements PipeTransform {
  transform(date: string | Date | undefined): string {
    if (!date) {
      return '';
    }

    const diffInMs = +new Date() - +new Date(date);
    const diffInMinutes = Math.floor(diffInMs / 60000);

    if (diffInMinutes < 3) {
      return 'just now';
    } else {
      const diffInHours = Math.floor(diffInMinutes / 60);
      const remainingMinutes = diffInMinutes - diffInHours * 60;

      if (diffInMinutes < 60) {
        return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
      } else if (diffInHours === 1 && remainingMinutes > 0) {
        return `1 hour, ${remainingMinutes} minute${
          remainingMinutes === 1 ? '' : 's'
        } ago`;
      } else if (diffInHours >= 1) {
        return `${diffInHours} hour${
          diffInHours === 1 ? '' : 's'
        }, ${remainingMinutes} minute${remainingMinutes === 1 ? '' : 's'} ago`;
      } else {
        return 'Invalid date';
      }
    }
  }
}

import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-filter-highlight',
  templateUrl: './filter-highlight.component.html',
  styleUrls: ['./filter-highlight.component.scss']
})
export class FilterHighlightComponent implements OnChanges {
  @Input() value = '';
  @Input() filterValue = '';

  beforeFilter = '';
  highlighted = '';
  afterFilter = '';

  ngOnChanges(): void {
    if (this.filterValue.length > 0) {
      const filterIndex = this.value
        .toLowerCase()
        .indexOf(this.filterValue.toLowerCase());
      if (filterIndex !== -1) {
        this.beforeFilter = this.value.slice(0, filterIndex);
        this.highlighted = this.value.slice(
          filterIndex,
          filterIndex + this.filterValue.length
        );
        this.afterFilter = this.value.slice(
          filterIndex + this.filterValue.length
        );
      } else {
        this.beforeFilter = this.value;
        this.highlighted = '';
        this.afterFilter = '';
      }
    } else {
      this.beforeFilter = this.value;
      this.highlighted = '';
      this.afterFilter = '';
    }
  }
}

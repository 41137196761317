<div class="modal-header">
  <h4 class="modal-title" id="sample-modal-title">
    Storage Account <br />
    <span class="text-secondary">{{ storageAccount?.name }}</span>
  </h4>
  <button
    type="button"
    class="btn btn-circle btn-sm btn-ghost element-cancel"
    aria-label="Close modal"
    (click)="onClose()"
  ></button>
</div>
<div class="modal-body">
  <div *ngIf="isLoading" class="loading-container text-center">
    <si-loading-spinner></si-loading-spinner>
    Loading Storage Account...
  </div>

  <div *ngIf="!isLoading && !hasError">
    <h4>Keys</h4>
    <div>
      <small class="text-secondary"
        >Storage account keys are secret credentials that grant access to data
        and can be used with tools like
        <a
          href="https://azure.microsoft.com/en-us/products/storage/storage-explorer"
          >Azure Storage Explorer</a
        >.</small
      >
      <div *ngFor="let key of storageAccount?.keys">
        <app-storage-account-key [key]="key"></app-storage-account-key>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading && hasError">
    <p>
      <strong>No storage account was found in this resource group.</strong>
    </p>
    <p>
      <small class="text-secondary">
        Storage Accounts offer secure, scalable storage services that benefit
        virtual machines (VMs) with robust data backup, disaster recovery, and
        distributed data access.
      </small>
    </p>
  </div>
</div>

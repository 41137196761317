import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VirtualMachine } from '../../../../../../services/virtual-machines.service';
import {
  StorageAccount,
  StorageAccountsService
} from '../../../../../../services/storage-accounts.service';

@Component({
  selector: 'app-storage-account-modal',
  templateUrl: './storage-account-modal.component.html',
  styleUrls: ['./storage-account-modal.component.scss']
})
export class StorageAccountModalComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Input() virtualMachine!: VirtualMachine;

  storageAccount?: StorageAccount;
  isLoading = false;
  hasError = false;

  constructor(
    private readonly storageAccountsService: StorageAccountsService
  ) {}

  ngOnInit(): void {
    const { resourceGroupName } = this.virtualMachine;

    this.isLoading = true;
    this.storageAccountsService
      .getStorageAccountByResourceGroup(resourceGroupName)
      .subscribe({
        next: (storageAccount) => {
          this.isLoading = false;
          this.storageAccount = storageAccount;
        },
        error: () => {
          // The resource group does not have a storage account.
          this.isLoading = false;
          this.hasError = true;
        }
      });
  }

  onClose(): void {
    this.close.emit();
  }
}

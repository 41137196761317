import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SiThemeService, ThemeType } from '@simpl/element-ng';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  private translationsSubject = new BehaviorSubject<Record<string, string>>({});
  translations$ = this.translationsSubject.asObservable();

  readonly availableLanguages = ['en'];

  constructor(
    private readonly translate: TranslateService,
    private readonly themeService: SiThemeService
  ) {
    translate.setDefaultLang('en');
    translate.onLangChange.subscribe((langChangeEvent) => {
      this.storeLanguage(langChangeEvent);
      this.translationsSubject.next(langChangeEvent.translations);
    });

    this.setLanguage('en');
    this.setTheme(this.theme);
  }

  get language() {
    return this.translate.currentLang;
  }

  get theme() {
    return this.loadTheme() ?? 'light';
  }

  setLanguage(language: string) {
    this.translate.use(language);
  }

  setTheme(theme: ThemeType) {
    this.themeService.applyThemeType(theme);
    this.storeTheme(theme);
  }

  private storeLanguage(event: LangChangeEvent) {
    localStorage.setItem('lang', event.lang);
  }

  private storeTheme(theme: ThemeType) {
    localStorage.setItem('theme', theme);
  }

  private loadTheme() {
    return localStorage.getItem('theme') as ThemeType | undefined;
  }
}

<td>
  <app-filter-highlight
    [value]="virtualMachine.computerName"
    [filterValue]="filterValue"
  ></app-filter-highlight>
</td>
<td>
  <app-filter-highlight
    [value]="virtualMachine.resourceGroupName"
    [filterValue]="filterValue"
  ></app-filter-highlight>
</td>
<td>
  <app-filter-highlight
    [value]="virtualMachine.department"
    [filterValue]="filterValue"
  ></app-filter-highlight>
</td>
<td>
  <app-filter-highlight
    [value]="virtualMachine.location"
    [filterValue]="filterValue"
  ></app-filter-highlight>
</td>
<td>
  <span *ngIf="virtualMachine.status === 'RUNNING'" class="status-running">
    <app-filter-highlight
      [value]="virtualMachine.status | capitalize"
      [filterValue]="filterValue"
    ></app-filter-highlight
  ></span>
  <span *ngIf="virtualMachine.status === 'STOPPED'" class="status-stopped"
    ><app-filter-highlight
      [value]="virtualMachine.status | capitalize"
      [filterValue]="filterValue"
    ></app-filter-highlight
  ></span>
  <span *ngIf="virtualMachine.status === 'UPDATING'" class="status-updating">
    <app-filter-highlight
      value="Updating"
      [filterValue]="filterValue"
    ></app-filter-highlight
    ><app-loading-dots [tickInterval]="500"></app-loading-dots>
  </span>
</td>
<td>
  <app-filter-highlight
    [value]="virtualMachine.createdDate | friendlyDate"
    [filterValue]="filterValue"
  ></app-filter-highlight>
</td>
<td>
  <app-filter-highlight
    [value]="virtualMachine.shutdownDate | friendlyDate"
    [filterValue]="filterValue"
  ></app-filter-highlight>
</td>
<td>
  <app-filter-highlight
    [value]="virtualMachine.operatingSystem"
    [filterValue]="filterValue"
  ></app-filter-highlight>
</td>
<td>
  <si-content-action-bar [primaryActions]="actions"></si-content-action-bar>
</td>

<ng-template #storageAccountTmpl let-modalRef="modalRef">
  <app-storage-account-modal
    [virtualMachine]="virtualMachine"
    (close)="storageAccountModal?.hide()"
  ></app-storage-account-modal>
</ng-template>

<ng-template #adminPasswordTmpl let-modalRef="modalRef">
  <app-admin-password-form
    (close)="adminPasswordModal?.hide()"
    (submit)="onChangeAdminPasswordSubmit($event)"
  ></app-admin-password-form>
</ng-template>

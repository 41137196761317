<div class="modal-header">
  <h4 class="modal-title" id="sample-modal-title">Change Admin Password</h4>
  <button
    type="button"
    class="btn btn-circle btn-sm btn-ghost element-cancel"
    aria-label="Close modal"
    (click)="onClose()"
  ></button>
</div>
<div class="modal-body">
  <p>
    Please complete the form below to update the password for the user
    <strong>vmadmin</strong>:
  </p>

  <form [formGroup]="signUpForm">
    <si-form-item label="Password" [required]="true">
      <div class="form-control-has-icon w-100">
        <div class="password-container">
          <si-password-strength>
            <input
              id="password"
              [type]="isPasswordVisible ? 'text' : 'password'"
              formControlName="password"
              (focus)="showPasswordHints = true"
              class="form-control w-100"
              [siPasswordStrength]="{
                minLength: 12,
                uppercase: true,
                lowercase: true,
                digits: true,
                special: true
              }"
            />
          </si-password-strength>

          <button
            type="button"
            class="btn btn-circle btn-sm btn-ghost"
            [class.element-show]="!isPasswordVisible"
            [class.element-hide]="isPasswordVisible"
            (click)="isPasswordVisible = !isPasswordVisible"
          ></button>
        </div>
        <div class="password-hints">
          <p class="mt-5 mb-2">Password must meet the following criteria:</p>
          <ul class="mb-0">
            <li
              [class.passed]="password.errors?.['password'].hasMinLength || !password.errors"
            >
              At least 12 characters in length
            </li>
            <li
              [class.passed]="password.errors?.['password'].hasLowerCase  || !password.errors"
            >
              Contains at least one lower case letter
            </li>
            <li
              [class.passed]="password.errors?.['password'].hasUpperCase  || !password.errors"
            >
              Contains at least one upper case letter
            </li>
            <li
              [class.passed]="password.errors?.['password'].hasNumber  || !password.errors"
            >
              Contains at least one number
            </li>
            <li
              [class.passed]="password.errors?.['password'].hasSpecialChar  || !password.errors"
            >
              Contains at least one special character (e.g. !@#$_%^&?*)
            </li>
          </ul>
        </div>
      </div>
    </si-form-item>

    <si-form-item label="Confirm Password" [required]="true">
      <div class="form-control-has-icon w-100">
        <div class="password-container">
          <input
            id="passwordConfirm"
            [type]="isPasswordConfirmVisible ? 'text' : 'password'"
            formControlName="passwordConfirm"
            class="form-control"
          />

          <button
            type="button"
            class="btn btn-circle btn-sm btn-ghost"
            [class.element-show]="!isPasswordConfirmVisible"
            [class.element-hide]="isPasswordConfirmVisible"
            (click)="isPasswordConfirmVisible = !isPasswordConfirmVisible"
          ></button>
        </div>
      </div>
      <div
        class="password-hints"
        *ngIf="
          passwordConfirm.dirty && password.value !== passwordConfirm.value
        "
      >
        <p class="mt-5 mb-2 red">Password and confirm password do not match</p>
      </div>
    </si-form-item>
  </form>
</div>
<div class="modal-footer">
  <button type="button" (click)="onClose()" class="btn btn-secondary">
    Cancel
  </button>
  <button
    type="button"
    (click)="onSubmit()"
    [disabled]="!signUpForm.valid || password.value !== passwordConfirm.value"
    class="btn btn-primary"
  >
    Submit
  </button>
</div>

import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AuthGuard,
  AuthHttpInterceptor,
  AuthModule
} from '@auth0/auth0-angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { environmentProvider } from './environment.provider';
import { MockApiInterceptor } from './mock-api.interceptor';
import getLastUrlSegment from './utils/get-last-url-segment';
import { RefreshTokenErrorHandler } from './refresh-token-error-handler';
import { SignUpFormComponent } from './pages/landing/sign-up-form/sign-up-form.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

// We use a mock backend when not in production.
// Remove the "!" to use the real backend (make sure it's listening on localhost:3000).
const USE_MOCK_BACKEND = !environment.isDeployed;

@NgModule({
  declarations: [AppComponent, LandingComponent, SignUpFormComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AuthModule.forRoot({
      ...environment.auth.auth0,
      authorizeTimeoutInSeconds: 300, // Give the user 5 minutes to complete or cancel the auth flow.
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
      httpInterceptor: {
        allowedList: [
          // The Auth0 SDK will attach the access token to
          // each request with this URL pattern.
          `${environment.cspBackendUrl}/api/*`
        ]
      }
    })
  ],
  providers: [
    { provide: ErrorHandler, useClass: RefreshTokenErrorHandler },
    environmentProvider,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    USE_MOCK_BACKEND
      ? {
          provide: HTTP_INTERCEPTORS,
          useClass: MockApiInterceptor,
          multi: true
        }
      : []
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private readonly router: Router, private readonly title: Title) {
    this.changeTitleOnNavigation();
  }

  private changeTitleOnNavigation() {
    this.router.events
      ?.pipe(filter(this.isNavigationEnd))
      .subscribe((navEnd) => {
        switch (getLastUrlSegment(navEnd.urlAfterRedirects)) {
          case 'landing':
            this.title.setTitle('Welcome | Cloud Service Portal');
            break;
          case 'virtual-machines':
            this.title.setTitle('Virtual Machines | Cloud Service Portal');
            break;
          default:
            // Unknown route.
            this.title.setTitle('Cloud Service Portal');
        }
      });
  }

  private isNavigationEnd(event: any): event is NavigationEnd {
    return event instanceof NavigationEnd;
  }
}

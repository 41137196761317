import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'friendlyDate'
})
export class FriendlyDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: string | Date | null): string {
    const notDefinedDate = '9999-12-31T00:00:00.000Z';

    if (!value || value.toString() === notDefinedDate) {
      return '- - - -'; // Display this message for undefined or not set dates
    }

    // If the date is defined, format it using the DatePipe
    return this.datePipe.transform(value, 'dd.MM.yyyy') as string;
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import generateRandomString from '../utils/create-random-string';

export interface Notification {
  id?: string;
  title: string;
  message: string;
  status: 'running' | 'success' | 'failed';
  createdDate?: string;
  notificationToReplace?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private notificationsSubject = new Subject<Notification>();

  notifications$ = this.notificationsSubject.asObservable();

  addNotification(incompleteNotification: Notification): string {
    const id = generateRandomString();
    const notification: Notification = {
      ...incompleteNotification,
      id,
      createdDate: new Date().toISOString()
    };

    this.notificationsSubject.next(notification);

    return id;
  }
}
